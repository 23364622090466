import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
//引入better-scroll
import BScroll from 'better-scroll'
import './style.scss'
let scroll = null;
export default class AppScroll extends PureComponent {
    //利用ref精准的获取dom元素
    wrapper = React.createRef();
  
    static propTypes = {
        startX: PropTypes.number,
        startY: PropTypes.number,
        tap: PropTypes.bool,
        mouseWheel: PropTypes.bool,
        scrollbar: PropTypes.bool,
        scrollY: PropTypes.bool,
        scrollX: PropTypes.bool,
        click: PropTypes.bool,
        directionLockThreshold: PropTypes.number,
        momentum: PropTypes.bool,
        bounce: PropTypes.bool,
        selectedIndex: PropTypes.number,
        rotate: PropTypes.number,
        wheel: PropTypes.bool,
        snap: PropTypes.bool,
        snapLoop: PropTypes.bool,
        snapThreshold: PropTypes.number,
        snapSpeed: PropTypes.number,
        swipeTime: PropTypes.number,
        bounceTime: PropTypes.number,
        adjustTime: PropTypes.number,
        swipeBounceTime: PropTypes.number,
        deceleration: PropTypes.number,
        momentumLimitTime: PropTypes.number,
        momentumLimitDistance: PropTypes.number,
        resizePolling: PropTypes.number,
        preventDefault: PropTypes.bool,
        preventDefaultException: PropTypes.object,
        HWCompositing: PropTypes.bool,
        useTransition: PropTypes.bool,
        useTransform: PropTypes.bool,
        probeType: PropTypes.number,
    }
    static defaultProps = {
        startX: 0, //  开始的X轴位置
        startY: 0, //  开始的Y轴位置
        tap: true, //  因为better-scroll会阻止原生的click事件,这个为true,就会在区域被点击的时候派发tap事件可以像监听原生事件去监听他
        mouseWheel: true, //  启用滚轮事件
        scrollbar: true, //  启用滚动条
        scrollY: true, //  滚动方向为 Y 轴
        scrollX: true, //  滚动方向为 X 轴
        click: true, //  是否派发click事件，通常判断浏览器派发的click还是betterscroll派发的click，可以用_constructed，若是bs派发的则为true
        directionLockThreshold: 5, // 
        momentum: true, //  当快速滑动时是否开启滑动惯性
        bounce: false, //  是否启用回弹动画效果
        selectedIndex: 0, //  wheel 为 true 时有效，表示被选中的 wheel 索引
        rotate: 25, //  wheel 为 true 时有效，表示被选中的 wheel 每一层的旋转角度
        wheel: false, //  该属性是给 picker 组件使用的，普通的列表滚动不需要配置
        snap: false, //  该属性是给 slider 组件使用的，普通的列表滚动不需要配置
        snapLoop: false, //  是否可以无缝循环轮播
        snapThreshold: 0, // .1 用手指滑动时页面可切换的阈值，大于这个阈值可以滑动的下一页
        snapSpeed: 400, // , 轮播图切换的动画时间
        swipeTime: 2500, //  swipe 持续时间
        bounceTime: 700, //  弹力动画持续的毫秒数
        adjustTime: 400, //  wheel 为 true 有用，调整停留位置的时间
        swipeBounceTime: 1200, //  swipe 回弹 时间
        deceleration: 0, // .001 滚动动量减速越大越快，建议不大于0.01
        momentumLimitTime: 300, //  符合惯性拖动的最大时间
        momentumLimitDistance: 15, //  符合惯性拖动的最小拖动距离
        resizePolling: 60, //  重新调整窗口大小时，重新计算better-scroll的时间间隔
        preventDefault: true, //  是否阻止默认事件
        preventDefaultException: { tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT)$/ },// 阻止默认事件
        HWCompositing: true, //  是否启用硬件加速
        useTransition: true, //  是否使用CSS3的Transition属性
        useTransform: true, //  是否使用CSS3的Transform属性
        probeType: 1, //  滚动的时候会派发scroll事件，会截流。2滚动的时候实时派发scroll事件，不会截流。 3除了实时派发scroll事件，在swipe的情况下仍然能实时派发scroll事件
    }
    state={
        scrollXY:{
            x:0,
            y:0
        }
    }
    render() {
     
        return (
            <div className="scroll-wrapper" ref={this.wrapper}>
                <div className="scroll-content">
                    {this.props.render(this.PushScroll,this.state.scrollXY, this.ScrollRefresh)}
                </div>
            </div>
        )
    }
    PushScroll=(el, time, offsetX, offsetY, easing)=>{
        // 滚动到某个元素，el（必填）表示 dom 元素，time 表示动画时间，offsetX 和 offsetY 表示坐标偏移量，easing 表示缓动函数
        scroll.scrollToElement(el, time, offsetX, offsetY, easing)

    }
    ScrollRefresh=()=>{
        scroll.refresh();
    };
    componentDidMount() {
        scroll = new BScroll(this.wrapper.current, {
            ...this.props
        })
        // 在用户需要滚动前及时更新滚动视图
        scroll.on('beforeScrollStart', (e) => {
          this.ScrollRefresh()
        })
        scroll.on('scrollEnd', (pos) => {
            // this.s
            this.setState({
                scrollXY:pos
            })
        })
    }
}