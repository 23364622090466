import React, { PureComponent} from 'react'
import hamburger from "@/css/hamburger.module.scss";
import  "./sidebar-toggle.scss";
import PropTypes from 'prop-types'
export default class toggle extends PureComponent{
    static propTypes = {
        showNavbar: PropTypes.func.isRequired,
    }
  
    render() {
        const {showNavbar} = this.props
        return (
            <div className="sidebar-toggle d-flex" onClick={showNavbar}>
                <i className={hamburger.hamburger+' align-self-center'}></i>
            </div>
        )
    }
}