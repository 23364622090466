import{ PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Condition extends PureComponent{
 static propTypes = {
  rif: PropTypes.bool
  };
  
  render() {
    return this.props.rif ? this.props.children : null;
  }
  // 核心思想是通过props来控制组件渲染，为true时渲染children
}

