import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { formContext } from './createContext.js';
import Modal from '@/components/Modal';

const { Provider } = formContext
export default class MyForm extends PureComponent {
    static propTypes = {
        defaultValue: PropTypes.object,
        verification: PropTypes.object,
        labelWitdth: PropTypes.string,
        Submit: PropTypes.func,
    }
    static defaultProps = {
        defaultValue: {},
        verification: {},
        labelWitdth: "120px",
        Submit: () => {

        },
    }
    state = {
        verificationList: {},
        isShow: false,
        message: ""
    }
     TellForm = (callback, key) => {
        const { verificationList } = this.state
        const obj =verificationList
        obj[key] =callback
        this.setState({
            verificationList: obj
        })
    }
    DelForm=(key)=>{
        const { verificationList } = this.state
        const obj =verificationList
        delete obj[key]
        this.setState({
            verificationList: obj
        })
    }
    handleSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        const { verificationList } = this.state
        console.log(verificationList)
        const arr = Object.values(verificationList)
        let checkNum = 0
        let message = []
        let Value = {}
        let key = []
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index]();
            const { requiredShow, label, value } = element
            const value_key = Object.keys(value)[0]
            const value_value = value[value_key]
            if (!requiredShow) {
                checkNum += 1
                if (value_value === "") continue
                if (key.indexOf(value_key) === -1) {
                    key.push(value_key)

                    // if (value_key === "null") continue
                    Value = { ...Value, ...value }
                } else {
                    const Value_key = Object.keys(value)[0]
                    const Value_value = Value[Value_key]
                    if (Array.isArray(Value_value)) {
                        Value[Value_key].push(value_value)
                    } else {
                        Value[Value_key] = []
                        Value[Value_key].push(Value_value)
                        Value[Value_key].push(value_value)
                    }
                    //  ()Value[Object.keys(value)[0]]
                }

            } else {
                message.push("〖" + label + "〗")
            }

        }
        if (checkNum === arr.length) {
            return Value
        } else {
            this.setState({ message: message.join(','), isShow: true })
            return false
        }

    }
    handleSubmits(event){
        event.preventDefault();
    }
    modalClose = () => {
        this.setState({ isShow: false, message: "" })
    }
    render() {
        const { isShow, message } = this.state
        const { children, verification, labelWitdth, defaultValue, } = this.props
        return (
            <Provider value={{ labelWitdth, defaultValue, TellForm: this.TellForm,DelForm:this.DelForm, verification }}>
                <Modal
                    zIndex={9999}
                    size='sm'
                    title="以下必填信息未被填写完整"
                    isShow={isShow}
                    show_submit={false}
                    bgc_type={'light'}
                    modalClose={this.modalClose}
                    close_text='确定'
                    cloBtn_type='danger'
                    header_type='danger'
                    render={() => {
                        return <p className="mb-0" style={{ textAlign: 'center', color: 'red' }}>{message}</p>
                    }}>
                </Modal>
                <div className="card">
                    <form onSubmit={this.handleSubmits}>
                        {children}
                    </form></div>
            </Provider>

        )
    }
}
