import React, { PureComponent, Fragment } from 'react'
import Navbar from '@/containers/Navbar'
import Main from './components/Main'
import './index.scss'
class Layout extends PureComponent{
    render() {
        return (
            <Fragment>
                 <Navbar></Navbar>
                 <Main {...this.props}></Main> 
            </Fragment>
        )
    }
}


export default Layout;