import React, { PureComponent} from 'react';
import Breadcrumb from '@/containers/Breadcrumb'
import Content from '@/containers/Content'
// import Footer from './components/Footer'
import main from './main.module.scss'
class Main extends PureComponent{
    render() {
        return (
            <div className={main.main}>
                <Breadcrumb></Breadcrumb>
                <Content {...this.props}></Content>
                {/* <Footer></Footer> */}
            </div>
        );
    }
}

export default Main;