import React, { PureComponent } from 'react'
import { btn } from '@/components/ReactTree/css/ReactTree.module.scss'
import { dropdownShowContext } from '@/components/ReactTree/js/createContext.js';
import Sidebar_Header from '@/components/ReactTree/css/Sidebar_Header.module.scss'
export default class index extends PureComponent {
    static contextType = dropdownShowContext
    AddBtnClick = () => {
        this.props.AddDelBtnClick(true)
    }
   DelBtnClick = () => {
        this.props.AddDelBtnClick(false)
    }
    FlipBtnClick = () => {
        this.props.FlipBtnClick(this.props.active)
    }
    render() {
        return (
            <div className={[Sidebar_Header['sidebar-header'],'DJfeAc'].join(' ')}>
                <button className={['btn', 'btn-success', btn].join(' ')} onClick={this.AddBtnClick} style={{marginRight:'8px'}}>全选</button>
                <button className={['btn', 'btn-danger', btn].join(' ')} onClick={this.DelBtnClick}>清空</button>
            </div>
        )
    }
}
