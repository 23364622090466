import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { dropdownShowContext } from '@/components/ReactTree/js/createContext.js';
import { evil } from '@/js/for.js';
import Sidebar_Header from '@/components/ReactTree/css/Sidebar_Header.module.scss'
import _SidebarItem from '@/components/ReactTree/css/sidebar_item.module.scss';
export default class SidebarItem extends PureComponent {
    constructor(props, state) {
        super(props, state)
        this.checkEvent = false
        this.SidebarLinkRef = React.createRef()
        const { Single: { open_val }, ParentActive } = this.context
        const { active } = this.props.this_item
        this.state = {
            active: open_val ? false : (active ? active : ParentActive)
        };
    }
    static contextType = dropdownShowContext
    static propTypes = {
        name: PropTypes.any,//当前行内容
        title: PropTypes.any,//当前行内容
        this_item: PropTypes.object.isRequired,// 当行的数据
        active: PropTypes.bool,//是否高亮选中
        ParentKey: PropTypes.string,//最顶层的标识
        marginLeft: PropTypes.bool
    }
    static defaultProps = {
        name: null,
        title: null,
        active: false,
        marginLeft: true


    };
   
    getSnapshotBeforeUpdate() {
        const { ParentActive } = this.context
        const { active } = this.state
        return ParentActive !== undefined ? ParentActive : active
    }
    componentDidUpdate(preProps, preState, ParentActive) {
        const { ParentClick } = this.context
        const { active } = this.state
        const { this_item } = this.props
        const { Single: { Single_val_key, open_val }, TreeSelectVal, changeAllLevle: _changeAllLevle } = this.context
        const { active: _active } = preState
        const changSelect = () => {
            let valStr = Single_val_key && Single_val_key.length ? 'this_item.' + Single_val_key : 'this_item'
            const existence = TreeSelectVal.indexOf(evil(valStr, 'this_item')(this_item)) !== -1

            if (existence && !_active && !active) {
                this.setState({ active: true }, () => {
                    _changeAllLevle(1);
                })
            } else if (!existence && _active && active) {
                this.setState({ active: false }, () => {
                    _changeAllLevle(-1);
                })
            }
        }
        if (!open_val) {
            if (_active === active && ParentClick) {
                this.setState({ active: ParentActive }, () => {
                    changSelect()
                })
            } else {
                changSelect()
            }
        }
    }
    componentDidMount() {
        const { Single: { open_val, Single_val_key }, TreeSelectVal, changeAllLevle: _changeAllLevle, Navigation, showeL, AllSelectVal } = this.context
        const { this_item } = this.props
        const { active = false, path } = this_item
        let valStr = Single_val_key && Single_val_key.length ? 'this_item.' + Single_val_key : 'this_item'
        const existence = TreeSelectVal.indexOf(evil(valStr, 'this_item')(this.props.this_item)) !== -1
        this.setState({
            active: false
        })
        if (existence) {
            this.setState({ active: true }, () => {
                _changeAllLevle(1);
            })
        } else {
            if (Navigation) {
                const seacrh =path.split("?")[1]?"?"+path.split("?")[1]:''
                if (window.location.pathname === path.split("?")[0] && window.location.search ===seacrh) {
                    this.SidebarLinkClick(this.SidebarLinkRef.current, true)
                    showeL()
                }
            } else {
                if (active && !open_val) {
                    // _changeAllLevle(1)//递归上去
                    this.tellTree();
                    showeL()
                } else if (!this.state.active&&!open_val) {
                    const All =JSON.stringify(AllSelectVal)
                    const item =JSON.stringify(this_item)
                    if (All.indexOf(item) !== -1) {
                        // _changeAllLevle(1)//递归上去
                        this.tellTree();
                        showeL()
                    } else {
                        this.setState({
                            active: false
                        })
                    }
                } else if (active && open_val) {
                    this.SidebarLinkClick(this.SidebarLinkRef.current, open_val)
                    showeL()
                }
            }

        }

    }
    handleClickOutside = (e) => {
        const { active_condition } = this.context;
        const callBack = (call = () => { }) => {
            this.setState({
                active: false
            }, () => {
                call()   //值改变后要干什么
                document.removeEventListener('click', this.handleClickOutside, true);
            })
        }
        active_condition(this.props, this.state, callBack)

    }
    checkboxGetEvent = (event) => {
        const { changeAllLevle: _changeAllLevle, setKey } = this.context
        this.setState({ active: event.target.checked }, () => {
            if (this.state.active) {
                _changeAllLevle(1)
                this.tellTree();
            } else {
                _changeAllLevle(-1);
                this.tellTree(true);
            }
            setKey()
        })
    }
    tellTree = (del = false) => {
        const { this_item } = this.props
        const { getTreeSelectVal, Single } = this.context
        let valStr = Single.Single_val_key && Single.Single_val_key.length ? 'this_item.' + Single.Single_val_key : 'this_item'
        getTreeSelectVal(evil(valStr, 'this_item')(this_item), del)
    }
    SidebarLinkClick = (e, open) => {
        const { Navigation } = this.context
        if (!open && !Navigation) {
            if (e.target.tagName === 'INPUT') return
            e.preventDefault()
            e.stopPropagation()
        }
        const { active } = this.state
        const { SidebarLinkFnTo, ScrollClick, PushScroll, switchtarget } = this.context
        const elUl = open ? e : switchtarget(e);
        const callBack = () => {
            this.setState({
                active: !active
            }, () => {
                if (!active) {
                    document.addEventListener('click', this.handleClickOutside, true);
                } else {
                    document.removeEventListener('click', this.handleClickOutside, true);

                }
            })
        }
        if (ScrollClick) PushScroll(elUl, 500)
        SidebarLinkFnTo(this.props, this.state, this.SidebarLinkRef, callBack)

    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    render() {

        const { active } = this.state
        const { name, title, ParentKey, marginLeft } = this.props
        // const { name, title, ParentKey} = this.props
        const { scrollXY: { y: scrollY }, Single } = this.context
        return (
            <li className={active && Single.open_val ? _SidebarItem["activelink"] : null} ref={this.SidebarLinkRef} style={{ marginLeft: marginLeft ? '20px' : null }} >
                {name ? <div className={Sidebar_Header["sidebar-header"]}>
                    {name}
                </div> : null
                }
                {/* style={{ marginLeft: marginLeft ? '20px' : null }} */}
                <div className={_SidebarItem["sidebar-item"]} >
                    <div className={_SidebarItem["sidebar-link"]} data-link="activelink" onClick={this.SidebarLinkClick}>
                        {!Single.open_val ? <div className={_SidebarItem["sidebar-Input"]} ><input className="form-check-input" type="checkbox" checked={active} value={ParentKey} onChange={this.checkboxGetEvent} /></div> : null}
                        {
                            this.props.render ? (this.props.render(scrollY).Icon ? <div className={_SidebarItem["sidebar-Img"]} >{this.props.render(scrollY).Icon}</div> : null) : null
                        }
                        <span className="align-middle">{title}</span>
                    </div>

                </div>
            </li>

        )
    }
}
