/* 
	该文件用于汇总所有的reducer为一个总的reducer
*/
//引入combineReducers，用于汇总多个reducer
import {combineReducers} from 'redux'
//引入为白名单的reducer
import redux from './reducers'
//引入为黑名单reducer
import blacklist from './blacklist'
//汇总所有的reducer变为一个总的reducer
export default  combineReducers({
	redux,
	blacklist
})
