/* 
	1.该文件是用于创建一个为Count组件服务的reducer，reducer的本质就是一个函数
	2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/
import { SHOW_NAVBAR,TOKEN_CHECK,ALERT_SHOW } from '../constant'

const initState = {
	show: true,
	authorization:true,
	AlertShow:false,
	token:'',
	admin:{
		account:'未命名'
	}
} //初始化状态
export default function whitelist(preState = initState, action) {
	// console.log('countReducer@#@#@#');
	//从action对象中获取：type、data
	// const { type, data } = action
	const { type,data } = action
	//根据type决定如何加工数据
	switch (type) {
		case SHOW_NAVBAR: 
			return { ...preState, show: !preState.show }
		case TOKEN_CHECK: 
			return { ...preState,authorization:data.authorization,token:data.token,admin:data.admin }
		case ALERT_SHOW: 
			return { ...preState,AlertShow:data.AlertShow }
		default:
			return preState 
	}
}