import React, { Component } from 'react';
import Group from "@/components/Form/group"
import Form from "@/components/Form/form"

export default class MyForm extends Component {
  formRef = React.createRef()
  Submit =  () => {
    const verification = this.formRef.current.handleSubmit()
    return verification
  }
  render() {
    console.log(this.props)
    return (
      <Form
        ref={this.formRef}
        defaultValue={this.props}
        verification={{
          old_password: {
            required: true,
          },
          password: {
            required: true,
          },
          password_confirm: {
            required: true,
          },
        }}>
        <Group type="password" name="old_password" label="原始密码" placeholder="请输入原始密码"  ></Group>
        <Group type="password" name="password" label="新密码" placeholder="请输入新密码"  ></Group>
        <Group type="password" name="password_confirm" label="确认密码" placeholder="请输入确认密码"  ></Group>
       
      </Form>
    )
  }
}
