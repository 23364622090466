import React, { PureComponent, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom'
import { router, redirect } from '@/js/routes/navbar.js'
import { nanoid } from 'nanoid'
import { withRouter } from "react-router-dom";
class Main extends PureComponent {
    //在更新之前获取快照
    state = {

    }
    static getDerivedStateFromProps(props, state) {
        const { history: { replace }, authorization, location } = props
        if (authorization) {
            replace('./login')

        } else {
            if (location.pathname === '/' && location.pathname !== redirect) {
                replace(redirect)
            }
        }
        return null
    }
    render() {
        return (
            <main className="content">
                <div className="container-fluid p-0">
                    <Suspense fallback={<h1>Loading...</h1>}>
                        <Switch>

                            {
                                router.map((item, index) => {
                                    return (
                                        <Route key={nanoid()}{...item}
                                        />
                                    )
                                })
                            }
                            <Redirect to={redirect} />
                        </Switch>
                    </Suspense>
                </div>
            </main>
        );
    }
}

export default withRouter(Main);