import React, { PureComponent} from 'react';
import {
    showNavbar,
    tree_search,

} from '@/redux/actions'
import { connect } from 'react-redux'
import Breadcrumb from '@components/Layout/components/Main/components/Breadcrumb';
// import Navbar from '@/components/Layout/components/Navbar';
class BreadcrumbUi extends PureComponent{
    tree_search =(val)=>{
        this.props.tree_search(val)
    }
    render() {
        return (
            <Breadcrumb  {...this.props}>
            </Breadcrumb>
        );
    }
}

export default connect(
    state => ({
        show: state.redux.show,
        tree_text: state.blacklist.tree_text,
        admin: state.redux.admin,
    }),
    { showNavbar,tree_search }
)(BreadcrumbUi)

