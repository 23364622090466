import React, { PureComponent } from 'react'
import './list_group_item.scss'
import '@/css/avatar.scss'
import PropTypes from 'prop-types'
import { AlertCircle, Bell, Home, UserPlus } from "react-feather"
import LazyImage from "@/components/easy-loadimage/easy-loadimage";
import Condition from '@/components/config/rif'
// import img from '@/img/avatars/avatar.jpg'
// import img1 from '@/img/avatars/avatar-5.jpg'
// import img2 from "@/img/avatars/avatar-2.jpg"
// import img3 from "@/img/avatars/avatar-3.jpg"
export default class index extends PureComponent {
    static propTypes = {
        JsonData: PropTypes.object,
    }
    dateDiff = function (hisTime, nowTime) {
        if (!arguments.length) return '';
        var arg = arguments,
            now = arg[1] ? arg[1] : new Date().getTime(),
            diffValue = now - arg[0],
            result = '',

            minute = 1000 * 60,
            second = 1000,
            hour = minute * 60,
            day = hour * 24,
            // halfamonth = day * 15,
            month = day * 30,
            year = month * 12,

            _year = diffValue / year,
            _month = diffValue / month,
            _week = diffValue / (7 * day),
            _day = diffValue / day,
            _hour = diffValue / hour,
            _min = diffValue / minute,
            _second = diffValue / second;

        if (_year >= 1) result = parseInt(_year) + "年前";
        else if (_month >= 1) result = parseInt(_month) + "个月前";
        else if (_week >= 1) result = parseInt(_week) + "周前";
        else if (_day >= 1) result = parseInt(_day) + "天前";
        else if (_hour >= 1) result = parseInt(_hour) + "个小时前";
        else if (_min >= 1) result = parseInt(_min) + "分钟前";
        else result = parseInt(_second) + "秒前";
        return result;
    }
    IconSwitch = (val) => {//如果是icon则是1,2,3,4否则就是图片
        switch (val) {
            case '1':
                return <i className="text-danger" data-feather="alert-circle">
                    <AlertCircle></AlertCircle>
                </i>;
            case '2':

                return <i className="text-warning" data-feather="alert-circle">
                    <Bell></Bell>
                </i>;
            case '3':

                return <i className="text-primary" data-feather="alert-circle">
                    <Home></Home>
                </i>;
            case '4':

                return <i className="text-success" data-feather="alert-circle">
                    <UserPlus></UserPlus>
                </i>;

            default:
                return <LazyImage
                    imageSrc={val}
                    alt="Image"
                    rounded={1}
                    preventImgClick

                />;


        }
    }
    ListClickFn = () => {
        return (event) => {
            if (this.props.JsonData.istClickFn) {
                this.props.JsonData.istClickFn(event, this.props.JsonData)
            }
        }
    }

    render() {
        const { imageSrc, title, describe, time } = this.props.JsonData
        return (
            <div className="list-group-item" onClick={this.ListClickFn(true)}>
                <div className="row g-0 align-items-center" >
                    <Condition rif={imageSrc ? true : false}>
                        <div className="col-2" >

                            <div className="avatar img-fluid DJcAc" >
                                {this.IconSwitch(imageSrc)}

                            </div>
                        </div>
                    </Condition>
                    <div className={imageSrc ? "col-10" : "col-12"} style={{ textAlign: this.props.align }}>
                        <Condition rif={title ? true : false}><div className="text-dark">{title}</div></Condition>
                        <Condition rif={describe ? true : false}> <div className="text-muted small mt-1">{describe}</div></Condition>
                        <Condition rif={time ? true : false}> <div className="text-muted small mt-1">{this.dateDiff(new Date(time).getTime())}</div></Condition>
                    </div>
                </div>
            </div>
        )
    }
}
