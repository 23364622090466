import React, { PureComponent} from 'react';

import { connect } from 'react-redux'

import Navbar from '@/components/Layout/components/Navbar';
// import Navbar from '@/components/Layout/components/Navbar';
class NavbarUi extends PureComponent{

    render() {
        return (
            <Navbar  {...this.props}>
            </Navbar>
        );
    }
}

export default connect(
    state => ({
        show: state.redux.show,
        tree_text: state.blacklist.tree_text
    }),
    {  }
)(NavbarUi)

