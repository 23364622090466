import React, { PureComponent } from 'react'
import NavLink from '@/components/Layout/components/NavLink';
export default class index extends PureComponent {
    render() {
        const { FooterDescribe, FooterTo, diyFooter } = this.props
        return (
            <div className="dropdown-menu-footer">
                {diyFooter ? diyFooter :
                    <NavLink to={FooterTo} className="text-muted">{FooterDescribe}</NavLink>
                }
            </div>
        )
    }
}
