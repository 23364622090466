import '@/css/base.scss';
import 'react-zmage/lib/zmage.css'
import wrapper from '@/css/wrapper.module.scss';
import React, { PureComponent, lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Layout from '@/components/Layout';
const Login = lazy(() => import('@/containers/login'))
export default class App extends PureComponent {

  render() {
    return (
      <div className={'App ' + wrapper.wrapper}>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Switch>
            <Route path="/login" component={Login}></Route>
            <Route path="/" component={Layout}></Route>
            <Redirect to="/" />
          </Switch>
        </Suspense>

      </div>
    )
  }
}
