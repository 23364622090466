import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
export default class SidebarDropdown extends PureComponent {
    dropdown = React.createRef()
    static propTypes = {
        childrenList: PropTypes.array,//子元素
    }
    static defaultProps = {
        childrenList: []
}
    render() {
        const { childrenList, children } = this.props
        return (
            <ul className={"list-unstyled collapse"}  data-parent="#sidebar" children={children ? children : childrenList}   >
            </ul>
        )
    }
}
