
/**
 * 网络请求配置
 */
import axios from "axios";
import ReactDOM from 'react-dom';
import React from 'react';
import Modal from '@/components/Modal';
import { store } from '@/redux/store'
const {getState}=store
let storeObj = getState().redux
const alertTip = (message,code) => {
  if(code === 11001||code ===11003){
    window.sessionStorage.clear()
    window.location.reload();
    return
  }
let div = document.createElement('div');
  document.body.appendChild(div);
  const modalClose = () => {
    
    div.remove();
  }

  ReactDOM.render(
    <Modal
    zIndex={9999}
      size='sm'
      title="系统错误"
      isShow={true}
      show_submit={false}
      bgc_type={'light'}
      modalClose={modalClose}
      close_text='确定'
      cloBtn_type='danger'
      header_type='danger'
      render={() => {
        return <p className="mb-0" style={{ textAlign: 'center', color: 'red' }}>{message}</p>
      }}>
    </Modal>,
    div);
}
axios.defaults.timeout = 100000;
axios.defaults.baseURL = "/api";
axios.defaults.withCredentials = true;

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    storeObj = getState().redux
    const urlType= config.url==="/Image/uploadImg" ||config.url==="/Image/uploadBatchImg"
    const {token} = storeObj
    config.data = urlType?config.data:JSON.stringify(config.data);
    config.headers = {
      "Content-Type":urlType?"application/x-www-form-urlencoded":"application/json",
      "crossDomain": true,
      "token":token,
     
    };
    // urlType?config.onUploadProgress= progressEvent =>progressEvent.loaded:config=config
    document.body.classList.add('loading-indicator');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.code !== 0) {
      alertTip(response.data.message,response.data.code)

    }
    document.body.classList.remove('loading-indicator');
    // if (response.data.errCode === 2) {
    //   console.log("过期");
    // }
    return response;
  },
  (error) => {
    console.log("请求出错：", error);
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
    }).then((response) => {
      landing(url, params, response.data);
      resolve(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}
export function Delete(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      params: params,
    }).then((response) => {
      resolve(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

//统一接口处理，返回数据
export default function request(fecth, url, param) {
  //  let _data = "";
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case "put":
        put(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case "get":
        get(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case "post":
        post(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case "delete":
        Delete(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}

//失败提示
function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details);
        break;
      case 401:
        alert("未授权，请登录");
        break;

      case 403:
        alert("拒绝访问");
        break;

      case 404:
        alert("请求地址出错");
        break;

      case 408:
        alert("请求超时");
        break;

      case 500:
        alert("服务器内部错误");
        break;

      case 501:
        alert("服务未实现");
        break;

      case 502:
        alert("网关错误");
        break;

      case 503:
        alert("服务不可用");
        break;

      case 504:
        alert("网关超时");
        break;

      case 505:
        alert("HTTP版本不受支持");
        break;
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
  if (data.code === -1) {
  }
}

