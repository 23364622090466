// 广度遍历
class Queue {
    top = 0 // 栈的长度
    list = []
    push(item) {
        this.top++;
        this.list.push(item) // 入栈操作

    }

    shift() {
        --this.top;
        return this.list.shift() // 出栈操作
    }

    peek() {
        return this.list[this.top - 1] // 查询栈顶元素
    }

}
// // 深度遍历
// class Stack {

//     top = 0 // 栈的长度
//     list = []

//     push(item) {
//         this.top++;
//         this.list.push(item) // 入栈操作
//     }

//     pop() {
//         --this.top;
//         return this.list.pop() // 出栈操作
//     }

//     peek() {
//         return this.list[this.top - 1] // 查询栈顶元素
//     }

// }

export const evil = (str,val) => {
    const Fn = Function
   return new Fn(val,'return '+str)
}
export const formatQueue = (data, key='item') => {
    let valStr = key!=='item'? 'item.'+key : 'item'
    let queue = new Queue()
    let valArr = []
    queue.push(data);
    while (queue.top) {
        let item = queue.shift()
    
        for (let i in item.children) {
            queue.push(item.children[i])
			// obj
			
        }
		
        if (!item.children) {
            valArr.push(evil(valStr, 'item')(item))
        }
    };

    return valArr
}

// function formatStack(data) {
//     let stack = new Stack()
//     stack.push(data);
//     while (stack.top) {
//         let item = stack.pop()
//         for (let i in item.children) {
//             stack.push(item.children[i])
//         }
//         console.log(item.id)
//     }
// }
export const filterTree = ({
	value,
	data,
	prop,
	filter,
	filterXor,
	children,
	visible,
	filterChildren = true,
	callback
}) => {
	// 默认赋值
	visible = visible || 'visible'
	children = children || 'children'
	prop = prop || 'id'

	const filterNodeMethod = function (value, data) {
		if (!value) {
			return true
		}
		let flag = (data[prop] || data.title||data.meta.title).indexOf(value) !== -1
		return filterXor ? !flag : flag
	}

	const traverse = function (node) {
		const childNodes = node[children] || []

		childNodes.forEach(child => {
			callback && callback(child)
			child[visible] =
				(!node.root && !filterChildren && node[visible]) ||
				filterNodeMethod.call(child, value, child, prop)

			traverse(child)
		})

		// if (!node[visible] && childNodes.length) {
		// 	let allHidden = true
		// 	allHidden = !childNodes.some(child => child[visible])

		// 	node[visible] = allHidden === false
		// }
		if (!value) {
			console.log(value,'value')
		}
	}
	let totalData = {
		root: true
	}
	totalData[children] = data
	traverse(totalData)

	if (filter) {
		let copyData = data.slice()
		let filterHandle = filterData => {
			return filterData.filter((item, index) => {
				// console.log(item)
				if (item[visible]) {
					if (item[children]) {
						item[children] = filterHandle(item[children])
					}
					return true
				}
				return false
			})
		}
		return filterHandle(copyData)
	}
}
