import request from './axios';
// 图片库管理 - code码换取图片地址
const ImageGetByCode = (data={})=>{
  return  new Promise((resolve, reject) => {
        request("get",'/Image/getByCode',data).then(res => {
          resolve (res);
        },error => {
          console.log("网络异常~",error);
          reject(error)
        })
      }) 
}
// 图片库管理 - 上传单图片
const ImageUploadImg = (data={},headers)=>{
  return  new Promise((resolve, reject) => {
        request("post",'/Image/uploadImg',data).then(res => {
          resolve (res);
        },error => {
          console.log("网络异常~",error);
          reject(error)
        })
      }) 
}
// 图片库管理 - 批量上传图片
const ImageUploadBatchImg= (data={})=>{
  return  new Promise((resolve, reject) => {
        request("post",'/Image/uploadBatchImg',data).then(res => {
          resolve (res);
        },error => {
          console.log("网络异常~",error);
          reject(error)
        })
      }) 
}

export {
    ImageGetByCode,
    ImageUploadImg,
    ImageUploadBatchImg
}
