/* 
	该文件专门为Count组件生成action对象
*/
import {SHOW_NAVBAR,TREE_SEARCH,TOKEN_CHECK,ALERT_SHOW} from '../constant'

//同步action，就是指action的值为Object类型的一般对象
export const showNavbar = data => ({type:SHOW_NAVBAR,data})
export const tree_search = data => ({type:TREE_SEARCH,data})
export const token_check = data => ({type:TOKEN_CHECK,data})
export const alert_set_show = data => ({type:ALERT_SHOW,data})

//异步action，就是指action的值为函数,异步action中一般都会调用同步action，异步action不是必须要用的。
// export const incrementAsync = (data,time) => {
// 	return (dispatch)=>{
// 		setTimeout(()=>{
// 			dispatch(increment(data))
// 		},time)
// 	}
// }