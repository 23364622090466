import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  { store, persistor} from './redux/store'
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'default-passive-events'
// import "feather-icons"
import { Provider } from 'react-redux'
// import './index.css';
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        {/* <React.StrictMode> */}
          <App persistor={persistor} />
        {/* </React.StrictMode> */}
      </PersistGate>
    </BrowserRouter>

  </Provider>
  
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
