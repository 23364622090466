import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Condition from '@/components/config/rif'
import Zmage from 'react-zmage'
import { nanoid } from 'nanoid'
import './easy-loadimage.scss'
import './Zmage.scss'
import "@/css/rounded.scss";

export default class Loadimage extends PureComponent {
    // 静态实例
    offsetTop = 0//图片距离顶部多少距离
    parentElementHeight = 0//父盒子高度
    state = {
        uid: null,
        loadImg: false,//是否显示loadImg图片
        isLoadError: false,//图片错误判断
        showImg: false,//是否显示图片
        showTransition: false,//类名判断
        startX: 0,//开始的滑动
        startY: 0,//开始的滑动
        moveEndX: 0,//移动中的滑动
        moveEndY: 0,//移动中的滑动
        clickY: '',//滑动或者点击
        scrollTop: this.props.scrollTop,
        _imageSrc:this.props.imageSrc

    }
    loadimageRef = React.createRef()
    //对接收的props进行：类型、必要性的限制
    static propTypes = {
        preventImgClick: PropTypes.any,//是否阻止点解事件
        sizeW: PropTypes.string,//宽
        sizeH: PropTypes.string,//高
        imageSrc: PropTypes.string.isRequired,//图片地址
        alt: PropTypes.string,//备注
        scrollTop: PropTypes.number,//滚动值
        rounded: PropTypes.number,//0方形图片,1圆形图片,2相册图片
        mode: PropTypes.string,//切割模式(未开放)
        loadingMode: PropTypes.string,//loding的类名
        openTransition: PropTypes.bool,//是否启用动画
        ZmageList: PropTypes.array,//灯笼图片的数组,如果为空就选当前图片地址[{src:'img',atl:'?'}]
        ZmageBackdrop: PropTypes.string,//灯笼图片的背景颜色
        ZmageDefaultPage: PropTypes.number,//灯笼图片的默认选定
        Zmagecontroller: PropTypes.object,//灯笼图片的按钮选定
        ZmageHotKey: PropTypes.object,//灯笼图片的键盘选定
        ZmageAnimate: PropTypes.object,//灯笼图片动画选定
        ZmageHideOnScroll: PropTypes.bool,//灯笼图片动画选定
    }
    static defaultProps = {
        rounded: 0,
        sizeW: '',
        alt: '',
        imageSrc: '',
        scrollTop: 0,
        mode: 'center',
        loadingMode: 'looming-gray',
        openTransition: true,
        ZmageHideOnScroll: false,
        ZmageList: [],
        preventImgClick: false,
        ZmageBackdrop: '',
        ZmageDefaultPage: 0,
        Zmagecontroller: {
            // 关闭按钮
            close: true,
            // 缩放按钮
            zoom: true,
            // 下载按钮
            download: true,
            // 旋转按钮
            rotate: true,
            // 翻页按钮
            flip: true,
            // 多页指示
            pagination: true,
        },
        ZmageHotKey: {
            // 关闭（ESC）
            close: true,
            // 缩放（空格）
            zoom: true,
            // 翻页（左右）
            flip: true,
        },
        ZmageAnimate: {
            browsing: true,
            // 翻页动画
            flip: "swipe",
            // 渐变 (set小於3時强制使用)
            // "fade",
            // 交叉渐变
            // "crossFade",
            // 翻页
            // "swipe",

        },



    }
    getSnapshotBeforeUpdate() {
        this.onScroll(this.props.scrollTop)
        return this.props.scrollTop
    }
    //组件更新完毕的钩子
    componentDidUpdate(preProps, preState, snapshotValue) {

    }

    componentDidMount() {
        this.offsetTop = this.loadimageRef.current.offsetTop

        if (this.loadimageRef.current.offsetParent) {
            this.parentElementHeight = this.loadimageRef.current.offsetParent.offsetHeight
        }
        this.init()
    }
    // 实例方法
    handlerTouchEvent = (event) => {
        const { startX, startY, moveEndX, moveEndY, clickY } = this.state
        // event.preventDefault();
        let X, Y
        //只跟踪一次触摸
        if (event.touches.length === 1 || event.touches.length === 0) {//书上这里有错
            const right = document.getElementById("zmageControlFlipRight");
            const left = document.getElementById("zmageControlFlipLeft");
            if (event.type === "touchstart") {
                this.setState({
                    startX: event.changedTouches[0].pageX,
                    startY: event.changedTouches[0].pageY,
                    clickY: '',
                })
            } else if (event.type === "touchend") {
                X = moveEndX - startX;
                Y = moveEndY - startY;
                if (clickY === "") {

                }
                else if (X > 0) {
                    // console.log("左往右滑", X, moveEndX, Y, moveEndY);
                    right.click()
                }
                else if (X < 0) {
                    // console.log("左往右滑", X, moveEndX, Y, moveEndY);
                    left.click()
                }
                else if (Y > 0) {
                    // console.log("上往下滑");
                }
                else if (Y < 0) {
                    // console.log("下往上滑");
                }
                else {
                    // console.log("滑了个寂寞", X, Y);
                }

            } else if (event.type === "touchmove") {
                this.setState({
                    moveEndX: event.changedTouches[0].pageX,
                    moveEndY: event.changedTouches[0].pageY,
                    clickY: event.changedTouches[0].pageY,
                })

            }

        }
    }
    // 生成全局唯一id
    generateUUID = () => {
        return nanoid()
    }
    onScroll = (scrollTop = 0) => {
        const { loadImg, isLoadError, } = this.state
        const { offsetTop, parentElementHeight } = this
        // 加载ing时才执行滚动监听判断是否可加载
        if (loadImg || isLoadError) return;
        if (scrollTop + offsetTop <= parentElementHeight) {
            this.setState({ loadImg: true });
        }

    }
    init = () => {//初始化id
        this.setState({ uid: "uid-" + this.generateUUID() });
    }

    handleImgLoad = (e) => {//成功的回调
        this.setState({ showImg: true });
        this.setState({ showTransition: true });
    }
    handleImgError = (e) => {//失败的回调
        this.setState({ isLoadError: true });
    }

    SwitchRounded = () => {
        const { rounded } = this.props
        switch (rounded) {
            case 0:
                return 'rounded ';
            case 1:
                return 'rounded-circle rounded ';
            default:
                return 'img-thumbnail rounded ';

        }
    }

    ImgClick = (e) => {//失败的回调
        e.preventDefault();
        e.stopPropagation();
        const { ZmageList, ZmageBackdrop, imageSrc, ZmageDefaultPage, ZmageHotKey, Zmagecontroller, ZmageHideOnScroll, ZmageAnimate, preventImgClick } = this.props
        const { showImg } = this.state
        if (preventImgClick) { 
            if(Object.prototype.toString.call(preventImgClick) === '[object Function]'){
                preventImgClick(this)
            }
            
            return
         }
        if (!ZmageList.length) {
            if (imageSrc === '' || !showImg) return
            Zmage.browsing({
                src: imageSrc,
                backdrop: ZmageBackdrop,
                controller: Zmagecontroller,
                hotKey: ZmageHotKey,
                hideOnScroll: ZmageHideOnScroll,
                animate: ZmageAnimate,

            })
        } else {
            Zmage.browsing({
                defaultPage: ZmageDefaultPage,
                set: ZmageList,
                backdrop: ZmageBackdrop,
                controller: Zmagecontroller,
                hotKey: ZmageHotKey,
                hideOnScroll: ZmageHideOnScroll,
                animate: ZmageAnimate,
            })
        }
        setTimeout(() => {
            const el = document.getElementById("zmage")
            el.addEventListener('touchstart', this.handlerTouchEvent)
            el.addEventListener('touchmove', this.handlerTouchEvent)
            el.addEventListener('touchend', this.handlerTouchEvent)

        }, 500)

    }


    render() {
        const { uid, loadImg, isLoadError, showImg, showTransition,_imageSrc } = this.state;
        const { mode, openTransition, loadingMode, alt, sizeH, sizeW, children } = this.props;

        return (

            <div className={(this.SwitchRounded()) + "easy-loadimage"} id={uid} ref={this.loadimageRef}
                style={{
                    height: sizeH !== '' ? sizeH : sizeH,
                    width: sizeW !== '' ? sizeW : sizeW
                }}
                onClick={this.ImgClick}
            >
                <Condition rif={children}>
                    <div className='children' >
                        {children}
                    </div>
                </Condition>
                <Condition rif={loadImg && !isLoadError}>
                    <img
                        src={_imageSrc}
                        mode={mode}
                        alt={alt}
                        className={(!openTransition ? 'origin-img no-transition' : showTransition && openTransition ? 'origin-img show-transition' : 'origin-img')}
                        style={{
                            display: showImg ? 'block' : 'none',

                        }}
                        onLoad={this.handleImgLoad}
                        onError={this.handleImgError}
                    />
                </Condition >
                <Condition rif={!(loadImg && !isLoadError) && isLoadError}>
                    <div className="loadfail-img"></div>
                </Condition >
                <div
                    className={"spin-circle loading-img  " + loadingMode}
                    style={{ display: !showImg && !isLoadError ? 'block' : 'none' }}
                ></div>
            </div >
        )
    }
}
