import request from './axios';
// 修改其他管理员数据
const AdminUpdate = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("put", '/Admin/update', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 管理员信息 - 授权角色

const AdminAuthorize = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("put", '/Admin/authorize', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 管理员信息 - 修改密码
const AdminUpdatePassword = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("post", '/Admin/updatePassword', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 管理员信息 - 添加
const AdminAdd = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("post", '/Admin/add', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//管理员信息 - 获取某管理员的登录日志
const AdminShowLoginLog = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Admin/showLoginLog', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//管理员信息 - 列表
const AdminList = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Admin/list', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//管理员信息 - 详情
const Adminget = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Admin/get', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//管理员信息 - 删除/批量删除
const AdminDelete = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("delete", '/Admin/delete', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}

export {
    AdminUpdate,
    AdminAuthorize,
    AdminUpdatePassword,
    AdminAdd,
    AdminShowLoginLog,
    AdminList,
    AdminDelete,
    Adminget
}
