import React, { PureComponent, Fragment } from 'react'
import upload from './upload.module.scss'
import LazyImage from "@/components/easy-loadimage/easy-loadimage";
import ClassCropperModal from "@/components/ClassCropperModal/ClassCropperModal";
import { ImageUploadBatchImg } from "@/js/Interface/Image"
import Modal from '@/components/Modal';
import PropTypes from 'prop-types'
import deleteImg from './image-delete.png'
import { nanoid } from 'nanoid'
const MAX_FILE_SIZE = 1 * 1024 * 1024 // 文件最大限制为5M
export default class index extends PureComponent {
    uploadRefs = React.createRef()
    static propTypes = {
        verification: PropTypes.object,
        handelChange: PropTypes.func,
        name: PropTypes.string,
        defaultValue: PropTypes.any

    }
    static defaultProps = {
        verification: {
            required: false,
            min: 0,
            max: 1
        },
        defaultValue: [],
        handelChange: () => { }
    }
    state = {
        DataCode: '',
        uploadImgIndex: 0,
        imgArr: [],
        classModalVisible: false,
        pattern: false,
        isShow: false,
        classModalFile: null,
        classResultImgUrl: null,
        name: "upload"
    }
    uploadId = nanoid() + 'upload'
    componentDidMount() {
        this.init()
        this.initData()
    }
    init = () => {
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
            document.getElementById(this.uploadId).removeAttribute("capture", "camera");
        }

    }
    modalClose = () => {
        this.setState({ isShow: false }, () => {
            this.uploadRefs.current.value = ""
        })
    }
    initData = () => {
        const { defaultValue, handelChange } = this.props
        if (!defaultValue.length || !Array.isArray(defaultValue)) return
        console.log(defaultValue, Array.isArray(defaultValue))
        const imgArr = defaultValue
        let arr = []
        imgArr.forEach(item => {
            arr.push(item.code)
        })
        this.setState({
            imgArr: imgArr, DataCode: arr
        }, () => {

            handelChange({ target: { value: this.state.DataCode } })
        })
    }
    selectImage = async (e) => {
        let MAX = false
        const formData = new FormData()
        const FilesArr = e.target.files
        for (var i = 0, len = FilesArr.length; i < len; i++) {
            if (FilesArr[i].size <= MAX_FILE_SIZE) {
                formData.append('img[' + i + ']', FilesArr[i])
            } else {
                MAX = true
            }
        }
        if (MAX) {
            this.setState({ isShow: true })
            return
        }
        const res = await ImageUploadBatchImg(formData)
        const { imgArr } = this.state
        const { handelChange, verification } = this.props
        const { max } = verification
        if (res.code === 0) {
            const { data } = res
            const arr = [...imgArr, ...data]
            if (max && (arr.length > max)) {
                arr.splice(0, (arr.length - max))
            }
            let DataCode = []
            arr.forEach(item => {
                DataCode.push(item.code)
            })
            this.setState({ imgArr: arr, DataCode }, () => {
                this.uploadRefs.current.value = ""
                handelChange({ target: { value: this.state.DataCode } })
            })
        }

    }
    // init=(data)=>{

    // }
    deleteUrl = (i) => {
        return (e) => {
            const { handelChange } = this.props
            const { imgArr, DataCode } = this.state
            let arr = imgArr
            let arr2 = DataCode
            arr.splice(i, 1)
            arr2.splice(i, 1)
            this.setState({
                imgArr: arr,
                DataCode: arr2,
            }, () => {
                handelChange({ target: { value: this.state.DataCode } })
            })
        }
    }
    handleClassFileChange = e => {
        const file = e.target.files[0]
        this.uploadRefs.current = e.target
        if (file) {
            if (file.size <= MAX_FILE_SIZE) {
                this.setState(
                    {
                        classModalFile: file // 先把上传的文件暂存在state中
                    },
                    () => {
                        this.setState({
                            classModalVisible: true // 然后弹出modal
                        })
                    }
                )
            } else {
                this.setState({ isShow: true })

            }
        }
    }
    handleChange = e => {
        this.uploadRefs.current = e.target
        this.selectImage(e)
    }
    handleGetResultImgUrl = key => files => {
        this.selectImage({ target: { files } })
    }
    pattern = () => {
        const { pattern } = this.state
        this.setState({
            pattern: !pattern
        })
    }

    render() {
        const { imgArr, classModalVisible,
            classModalFile,
            pattern,
            isShow
        } = this.state
        const { max } = this.props.verification
        return (
            <Fragment>
                {classModalVisible && (
                    <ClassCropperModal
                        uploadedImageFile={classModalFile}
                        onClose={() => {
                            this.setState({ classModalVisible: false })
                        }}
                        onSubmit={this.handleGetResultImgUrl('classResultImgUrl')}
                    />
                )}


                <div className={upload["image-div"]}>
                    <div>
                        点击切换模式:   <button type="button" className="btn btn-primary" onClick={this.pattern} >{!pattern ? '裁剪' : "多张上传"}</button>
                    </div>
                    <div className={upload["upload-div"]} >

                        <ul className={upload["upload-ul"]} >
                            <li className={upload["upload-li"]} >

                                <div className={upload["title"]} >
                                    {max && max - imgArr.length ?
                                        '  可上传: ' + (max - imgArr.length) + '张'
                                        : "继续上传覆盖"}
                                </div>
                                <div className={upload["img-input-form"]} encType="multipart/form-data" style={{ opacity: 0 }}>
                                    {pattern ?
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/jpg,image/png"
                                            onChange={this.handleClassFileChange}
                                        /> : <input type="file" accept="image/*" capture="camera" onChange={this.handleChange} multiple="multiple" ref={this.uploadRefs} id={this.uploadId} />
                                    }
                                </div>
                                <div className={upload["item"]}>
                                    <span className={upload["photo-span"]}></span>
                                    <span className={upload["circle-span"]}></span>
                                    <span className={upload["circle-solid-span"]}></span>
                                </div>

                            </li>
                            {
                                imgArr.map(({ host, path }, index) => {
                                    return (
                                        <li className={upload["upload-li"]} key={nanoid()}>
                                            <div className={upload["item image"]}>
                                                <LazyImage
                                                    className={upload["upload-image"]}
                                                    imageSrc={host + path}
                                                    alt="200x300"
                                                    sizeH="131px"
                                                    sizeW="131px"
                                                />
                                                {/* <img className="upload-image" src={url} /> */}
                                                <img className={upload["delete-image"]} src={deleteImg} alt="200x300" onClick={this.deleteUrl(index)} />
                                            </div></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {isShow ? <Modal
                        zIndex={9999}
                        size='sm'
                        title="系统错误"
                        isShow={isShow}
                        show_submit={false}
                        bgc_type={'light'}
                        modalClose={this.modalClose}
                        close_text='确定'
                        cloBtn_type='danger'
                        header_type='danger'
                        render={() => {
                            return <p className="mb-0" style={{ textAlign: 'center', color: 'red' }}>图片大于1M</p>
                        }}>
                    </Modal> : null}

                </div>

            </Fragment>
        )
    }
}
