import React, { PureComponent} from 'react'
import './input_group_navbar.scss'
import {Search} from 'react-feather';
import PropTypes from 'prop-types'

export default class index extends PureComponent{
    static propTypes = {
        SearchFn: PropTypes.func.isRequired,//第一层路由的children
        placeholder:PropTypes.string
    }
    static defaultProps={
        placeholder : '请输入搜索内容...'
    }
    Search = React.createRef()
    SearchData = ()=>{
        const{Search:{current:{value}},props:{SearchFn}} =this
         SearchFn(value)
        
    }
    onKeyDownchange = (e)=>{
        if(e.keyCode === 13){
            this.SearchData()
            //事件操作
        }
        
    }
    handleSubmit(event){
        event.preventDefault();
    }
    render() {
        return (
            <form   onSubmit={this.handleSubmit}>
                    <div className="input-group input-group-navbar">
                        <input type="text" className="form-control" placeholder={this.props.placeholder} aria-label="Search"  ref={this.Search}  onKeyDown={e=> this.onKeyDownchange(e)} />
                        <button className="btn" type="button"onClick={this.SearchData}>
                            <i className="align-middle" data-feather="search">
                               <Search></Search>
                            </i>
                        </button>
                    </div>
                </form>
        )
    }
}
