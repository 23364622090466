import React from 'react'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
import { ImageUploadImg } from "@/js/Interface/Image"

// 引入编辑器样式
import 'braft-editor/dist/index.css'
import PropTypes from 'prop-types'
import "./editor.scss"
import Modal from '@/components/Modal';
const color = [
  '#AAAAAA',
  '#888888',
  '#666666',
  '#444444',
  '#000000',
  '#FFB7DD',
  '#FF88C2',
  '#FF44AA',
  '#FF0088',
  '#C10066',
  '#A20055',
  '#8C0044',
  '#FFCCCC',
  '#FF8888',
  '#FF3333',
  '#FF0000',
  '#CC0000',
  '#AA0000',
  '#880000',
  '#FFC8B4',
  '#FFA488',
  '#FF7744',
  '#FF5511',
  '#E63F00',
  '#C63300',
  '#A42D00',
  '#FFDDAA',
  '#FFBB66',
  '#FFAA33',
  '#FF8800',
  '#EE7700',
  '#CC6600',
  '#BB5500',
  '#FFEE99',
  '#FFDD55',
  '#FFCC22',
  '#FFBB00',
  '#DDAA00',
  '#AA7700',
  '#886600',
  '#FFFFBB',
  '#FFFF77',
  '#FFFF33',
  '#FFFF00',
  '#EEEE00',
  '#BBBB00',
  '#888800',
  '#EEFFBB',
  '#DDFF77',
  '#CCFF33',
  '#BBFF00',
  '#99DD00',
  '#88AA00',
  '#668800',
  '#CCFF99',
  '#BBFF66',
  '#99FF33',
  '#77FF00',
  '#66DD00',
  '#55AA00',
  '#227700',
  '#99FF99',
  '#66FF66',
  '#33FF33',
  '#00FF00',
  '#00DD00',
  '#00AA00',
  '#008800',
  '#BBFFEE',
  '#77FFCC',
  '#33FFAA',
  '#00FF99',
  '#00DD77',
  '#00AA55',
  '#008844',
  '#AAFFEE',
  '#77FFEE',
  '#33FFDD',
  '#00FFCC',
  '#00DDAA',
  '#00AA88',
  '#008866',
  '#99FFFF',
  '#66FFFF',
  '#33FFFF',
  '#00FFFF',
  '#00DDDD',
  '#00AAAA',
  '#008888',
  '#CCEEFF',
  '#77DDFF',
  '#33CCFF',
  '#00BBFF',
  '#009FCC',
  '#0088A8',
  '#007799',
  '#CCDDFF',
  '#99BBFF',
  '#5599FF',
  '#0044BB',
  '#003C9D',
  '#003377',
  '#CCCCFF',
  '#9999FF',
  '#5555FF',
  '#0000FF',
  '#0000CC',
  '#0000AA',
  '#000088',
  '#CCBBFF',
  '#9F88FF',
  '#DDDDDD',
  '#7744FF',
  '#5500FF',
  '#4400CC',
  '#2200AA',
  '#220088',
  '#D1BBFF',
  '#B088FF',
  '#9955FF',
  '#7700FF',
  '#5500DD',
  '#4400B3',
  '#3A0088',
  '#E8CCFF',
  '#D28EFF',
  '#B94FFF',
  '#9900FF',
  '#7700BB',
  '#66009D',
  '#550088',
  '#F0BBFF',
  '#E38EFF',
  '#E93EFF',
  '#CC00FF',
  '#A500CC',
  '#7A0099',
  '#660077',
  '#FFB3FF',
  '#FF77FF',
  '#FF3EFF',
  '#FF0',
  '#CC00CC',
  '#990099',
  '#770077',
  '#800000',
  '#8B0000',
  '#A52A2A',
  '#B22222',
  '#DC143C',
  '#FF0000',
  '#C71585',
  '#D87093',
  '#FF1493',
  '#FF00FF',
  '#FF69B4',
  '#FFC0CB',
  '#FFB6C1',
  '#FFE4E1',
  '#FFF0F5',
  '#4B0082',
  '#800080',
  '#8B008B',
  '#9932CC',
  '#8A2BE2',
  '#9400D3',
  '#6A5ACD',
  '#9370DB',
  '#7B68EE',
  '#BA55D3',
  '#EE82EE',
  '#DDA0DD',
  '#D8BFD8',
  '#E6E6FA',
  '#8B4513',
  '#A0522D',
  '#D2691E',
  '#CD5C5C',
  '#BC8F8F',
  '#F08080',
  '#FA8072',
  '#FFA07A',
  '#FF4500',
  '#FF6347',
  '#FF7F50',
  '#FF8C00',
  '#F4A460',
  '#CD853F',
  '#D2B48C',
  '#DEB887',
  '#0066FF',
  '#FFEFD5',
  '#FFF8DC',
  '#FDF5E6',
  '#FAF0E6',
  '#FFF5EE',
  '#FFFAFA',
  '#FFFAF0',
  '#FFFFF0',
  '#F5FFFA',
  '#B8860B',
  '#DAA520',
  '#FFD700',
  '#FFFF00',
  '#BDB76B',
  '#F0E68C',
  '#EEE8AA',
  '#F5F5DC',
  '#FFFACD',
  '#FAFAD2',
  '#FFFFE0',
  '#2F4F4F',
  '#556B2F',
  '#808000',
  '#006400',
  '#228B22',
  '#2E8B57',
  '#008080',
  '#20B2AA',
  '#66CDAA',
  '#3CB371',
  '#8FBC8F',
  '#9ACD32',
  '#32CD32',
  '#00FF00',
  '#7FFF00',
  '#7CFC00',
  '#ADFF2F',
  '#00FA9A',
  '#00FF7F',
  '#90EE90',
  '#98F898',
  '#7FFFD4',
  '#F0FFF0',
  '#191970',
  '#000080',
  '#00008B',
  '#483D8B',
  '#0000CD',
  '#4169E1',
  '#1E90FF',
  '#6495ED',
  '#00BFFF',
  '#87CEFA',
  '#B0C4DE',
  '#ADD8E6',
  '#4682B4',
  '#008B8B',
  '#5F9EA0',
  '#00CED1',
  '#48D1CC',
  '#40E0D0',
  '#87CECB',
  '#B0E0E6',
  '#AFEEEE',
  '#E0FFFF',
  '#F0FFFF',
  '#F0F8FF',
  '#00FFFF',
  '#000000',
  '#696969',
  '#808080',
  '#708090',
  '#F5DEB3',
  '#FFE4B5',
  '#FFDEAD',
  '#FFDAB9',
  '#FFE4C4',
  '#FAEBD7',
  '#2469ce',
  '#778899',
  '#A9A9A9',
  '#C0C0C0',
  '#D3D3D3',
  '#DCDCDC',
  '#F5F5F5',
  '#F8F8FF',
  '#FFFFFF',
]
export default class EditorDemo extends React.Component {
  static propTypes = {
    htmlContent: PropTypes.string,
    saveEditorContent: PropTypes.func,
    onChange: PropTypes.func,
    save: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,

  }
  static defaultProps = {
    htmlContent: '',
    placeholder: '',
    save: true,
    saveEditorContent: () => {

    },
    onChange: () => {

    }

  }
  state = {
    isShow: false,
    // 创建一个空的editorState作为初始值
    editorState: BraftEditor.createEditorState(null)
  }

  async componentDidMount() {
    // 假设此处从服务端获取html格式的编辑器内容
    const { htmlContent } = this.props
    // 使用BraftEditor.createEditorState将html字符串转换为编辑器需要的editorStat
    this.setState({
      editorState: BraftEditor.createEditorState(htmlContent)
    })
  }

  submitContent = async () => {
    // 在编辑器获得焦点时按下ctrl+s会执行此方法
    // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
    const htmlContent = this.state.editorState.toHTML()
    this.props.saveEditorContent(htmlContent)
    if (htmlContent !== "<p></p>") {
      this.setState({ isShow: true })
    }
    // const result = await saveEditorContent(htmlContent)
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState }, () => {
      this.props.onChange(this.state.editorState.toHTML())
    })
  }
  modalClose = () => {
    this.setState({ isShow: false })
  }
  myUploadFn = async (param) => {
    const successFn = (response) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: response,
      })
    }

    // const progressFn = (event) => {
    //   // 上传进度发生变化时调用param.progress
    //   param.progress(event.loaded / event.total * 100)
    // }

    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: response
      })
    }
    const fd = new FormData()
    fd.append('img', param.file)
    const res = await ImageUploadImg(fd)
    const { code } = res
    //   progressFn()
    if (code === 0) {
      const { data } = res
      const { host, path } = data
      successFn(host + path)
    } else {
      errorFn(res.message)
    }

  }
  render() {

    const { editorState, isShow } = this.state
    const { placeholder, save } = this.props
    return (
      <div className="my-component">
        <Modal
          zIndex={9999}
          size='sm'
          title="提示"
          isShow={isShow}
          show_submit={false}
          bgc_type={'light'}
          modalClose={this.modalClose}
          close_text='确定'
          cloBtn_type='primary'
          header_type='primary'
          render={() => {
            return <p className="mb-0" style={{ textAlign: 'center', color: '#' }}>保存成功</p>
          }}>
        </Modal>
        {save ?
          <div className="btn">  <button type="button" className="btn btn-primary" onClick={this.submitContent}>保存</button></div>
          : ''}
        <BraftEditor
          colors={color}
          className="bf-container"
          value={editorState}
          placeholder={placeholder}
          onChange={this.handleEditorChange}
          onSave={this.submitContent}
          media={{ accepts: { audio: false, video: false }, uploadFn: this.myUploadFn, }}
        />

      </div>
    )

  }

}