import React, { PureComponent, Fragment } from 'react'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import SidebarDropdown from '../SidebarDropdown'
import SidebarItem from '../SidebarItem'
import { dropdownShowContext } from '@/components/ReactTree/js/createContext.js';
import { formatQueue } from '@/js/for.js';
import _SidebarItem from '@/components/ReactTree/css/sidebar_item.module.scss';
import Sidebar_Header from '@/components/ReactTree/css/Sidebar_Header.module.scss'
import LazyImage from "@/components/easy-loadimage/easy-loadimage";
const { Provider } = dropdownShowContext
export default class MultiLevelRouting extends PureComponent {
    constructor(props, state) {
        super(props, state)
        const { child } = this.props;
        const { Single: { open_val }, ParentActive } = this.context
        const { active, SelectVal } = this.props.this_item
        this.check_input = React.createRef();
        this.link = React.createRef();
        this.LevelLength = child.length;
        this.selectVal = [];
        this.PreSelectVal = SelectVal ? SelectVal : [];
        this.sonArr = []
        this.AllLevelLength = 0
        this.state = {
            child: child,
            childrenList: [],//显示的子节点
            // active: this.context.ParentActive
            active: open_val ? false : (active ? active : ParentActive)
        };
    }

    static contextType = dropdownShowContext;
    static propTypes = {
        child: PropTypes.array.isRequired,//第一层路由的children
        name: PropTypes.any,//祖父的名字
        this_item: PropTypes.object.isRequired,// 当行的数据
        ParentKey: PropTypes.string,//此层的标识
        marginLeft: PropTypes.bool,

    };
    static defaultProps = {
        marginLeft: true
    }
    showeL=()=>{
        this.getEvent(this.link.current, true, true)
    }
    getSnapshotBeforeUpdate() {
        const { ParentActive } = this.context
        const { active } = this.state
        return ParentActive !== undefined ? ParentActive : active
    }
    componentDidUpdate(preProps, preState, ParentActive) {
        const { ParentClick } = this.context
        const { active } = this.state
        if (preState.active === active) {
            if (ParentClick) {//判断是子元素点击还是父元素点击
                this.setState({ active: ParentActive, ParentClick: ParentClick })
                this.AllLevelLength = this.LevelLength
            }
        }
    }

    componentDidMount() {
        const { Single: { open_val }, init = false } = this.context
        const { open = false, active=false, children = [] } = this.props.this_item
        if ((open || init) && children.length) {
            this.getEvent(this.link.current, open, init)

        }
        if (active && !open_val) {
            const { changeAllLevle: _changeAllLevle } = this.context
            _changeAllLevle(1)//递归上去
            this.AllLevelLength = this.LevelLength //全选当前选中和自身数量相等
            this.tellTree();
        }
    }
    childrenForEach = (event, open = false, init = false) => {//触发子集元素加载
        const { childrenList: _childrenList, active, child=[] } = this.state
        const { dropdownShow, ReactTreeClickEvent, switchtarget, ScrollClick, PushScroll } = this.context
        const elUl = open || init ? event.parentElement : switchtarget(event);
        const { rounded } = this.props;
        let childrenList = [];//展示的元素
        const MultipleChildrenForEach = (item, row) => {//触发多层子集元素加载
            const {
                // meta: { title },
                name = false,
                children = false
            } = item
            const ParentKey1 = nanoid()
            childrenList.push(
                <MultiLevelRouting
                    key={ParentKey1}
                    ParentKey={ParentKey1}
                    this_item={{ ...row, open }}
                    title={item.meta.title}
                    name={name}
                    child={children}
                    render={(scrollY) => {
                        return {
                            Icon: Object.prototype.toString.call(item.img) === "[object String]" ? <LazyImage
                                imageSrc={item.img}
                                rounded={rounded}
                                alt="Image"
                                sizeW='20px'
                                sizeH='20px'
                                scrollTop={scrollY}
                                preventImgClick
                            /> : item.img
                        }
                    }}
                >
                </MultiLevelRouting>
            )
        }
        if (child.length) {
            if (init && !open) {

            } else {
                if (ScrollClick) PushScroll(elUl, 500);
                if (_childrenList.length) {
                    dropdownShow(event, open)//把元素添加进去,再触发父节点的显示方法,达到懒加载的效果
                    if (ReactTreeClickEvent) ReactTreeClickEvent(elUl, _childrenList, this, this.handleClickOutside)//把元素当前的信息传出去
                    return
                };
            }

            child.forEach((childItem, index) => {
                const ParentKey3 = nanoid()
                if (childItem.children ) {
                    MultipleChildrenForEach(childItem, childItem.children[index])
                } else {
                    childrenList.push(
                        <SidebarItem
                            key={ParentKey3}
                            this_item={childItem}
                            title={childItem.title ||childItem.meta.title}
                            ParentKey={ParentKey3}
                            active={active}
                            open={open}
                            render={(scrollY) => {
                                return {
                                    Icon: Object.prototype.toString.call(childItem.img) === "[object String]" ? <LazyImage
                                        imageSrc={childItem.img}
                                        rounded={rounded}
                                        alt="Image"
                                        sizeW='20px'
                                        sizeH='20px'
                                        scrollTop={scrollY}
                                        preventImgClick
                                    /> : childItem.img
                                }
                            }}
                        />)
                }

            })

            this.setState({
                childrenList
            }, () => {
                if (init && !open) {

                } else {
                    dropdownShow(event, open)//把元素添加进去,再触发父节点的显示方法,达到懒加载的效果
                    if (ReactTreeClickEvent) ReactTreeClickEvent(elUl, childrenList, this, this.handleClickOutside)//把元素当前的信息传出去
                }

            })
        } else {
            if (ReactTreeClickEvent) {
                const callBack=()=>{
                    setTimeout(()=>{
                        this.getEvent(this.link.current,true)

                    },200)
                }
                ReactTreeClickEvent(elUl, _childrenList, this,callBack)
             
                //把元素当前的信息传出去
            }
        }


    }
    handleClickOutside = () => {
        const { active } = this.state
        this.setState({
            active: !active
        })
    }
    changeAllLevle = (num) => {//下级要触发的选中
        const { active } = this.state
        const { changeAllLevle: _changeAllLevle } = this.context//拿上级给的触发
        this.AllLevelLength += num
        // if(this.AllLevelLength>this.LevelLength){
        //     this.AllLevelLength = this.LevelLength
        //     return
        // }
        if (this.AllLevelLength === this.LevelLength && !active) {//选中的下级数量和本级的总数量相等
            this.setState({
                active: true,
                ParentClick: false
            }, () => {
                _changeAllLevle(1)//递归上去
                this.PreSelectVal = this.selectVal // 记录一下遍历的数据,性能优化
            })
        } else {
            this.setState({
                active: false,
                ParentClick: false
            }, () => {
                if (this.AllLevelLength === this.LevelLength - 1 && _changeAllLevle && num === -1) {
                    _changeAllLevle(-1)//递归上去
                }
            })
        }
    }
    checkboxGetEvent = (event) => {
        const { changeAllLevle: _changeAllLevle, setKey } = this.context
        this.setState({ active: event.target.checked, ParentClick: true }, () => {
            if (this.state.active) {
                _changeAllLevle(1)//递归上去
                this.AllLevelLength = this.LevelLength //全选当前选中和自身数量相等
                this.tellTree();
            } else {
                _changeAllLevle(-1);//递归上去
                this.AllLevelLength = 0;
                this.tellTree(true);
                this.selectVal = []
            }
            setKey()
        })

    }
    tellTree = (del = false) => {
        const { this_item } = this.props
        const { Single, getTreeSelectVal } = this.context
        if (this.PreSelectVal.length) { // 如果有遍历过直接拿
            this.selectVal = this.PreSelectVal
        } else {
            this.PreSelectVal = this.selectVal = formatQueue(this_item, Single.Single_val_key)//广式遍历
        }
        getTreeSelectVal(this.selectVal, del)//树节点处理点击的数据,del是true就是删除否则就是增加
    }
    getEvent = (event, open = false, init = false) => {//获取点击的dom
        if (!open && !init) {
            if (event.target.tagName === 'INPUT') return
            event.preventDefault()
            event.stopPropagation()
        }


        this.childrenForEach(event, open, init)//如果点击的节点是首曾路由,那么直接渲染
    }
    render() {
        const { title, name, ParentKey, marginLeft } = this.props
        // const { title, name, ParentKey} = this.props
        const { active, ParentClick } = this.state
        const { scrollXY: { y: scrollY }, Single } = this.context
        return (
            <Fragment >
                {name ? <li className={Sidebar_Header["sidebar-header"]} >
                    {name}
                </li> : null
                }
                <li className={active && Single.open_val ? [_SidebarItem["active"], _SidebarItem["sidebar-item"]].join(' ') : _SidebarItem["sidebar-item"]}   style={{ marginLeft: marginLeft ? '20px' : null }}>
                {/* style={{ marginLeft: marginLeft ? '20px' : null }} */}
                    <div data-toggle="collapse" className={[_SidebarItem["sidebar-link"], _SidebarItem["collapsed"]].join(" ")} aria-expanded={false} data-link={active ? "active" : ""} ref={this.link} onClick={this.getEvent} >
                        {!Single.open_val ? <div className={_SidebarItem["sidebar-Input"]} ><input className="form-check-input" type="checkbox" checked={active} value={ParentKey} onChange={this.checkboxGetEvent} /></div> : null}
                        {
                            this.props.render ? (this.props.render(scrollY).Icon ? <div className={_SidebarItem["sidebar-Img"]} >{this.props.render(scrollY).Icon}</div> : null) : null
                        }
                        <span className="align-middle"> {title}</span>
                    </div>
                    <Provider value={{
                        ...this.context,
                        showeL:this.showeL,
                        ParentActive: active,
                        ParentClick: ParentClick,
                        handleClickOutside: this.handleClickOutside,
                        changeAllLevle: this.changeAllLevle,
                    }}>
                        <SidebarDropdown childrenList={this.state.childrenList} >
                        </SidebarDropdown>
                    </Provider>
                </li>
            </Fragment>
        );
    }
}
