import React, { PureComponent} from 'react';

import { connect } from 'react-redux'

import Content from '@components/Layout/components/Main/components/Content';
// import Navbar from '@/components/Layout/components/Navbar';
class ContentUi extends PureComponent{

    render() {
        return (
            <Content  {...this.props}>
            </Content>
        );
    }
}

export default connect(
 
    state => ({
        authorization: state.redux.authorization,
        token: state.redux.token,
    }),
)(ContentUi)

