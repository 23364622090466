import React, { PureComponent } from 'react'

export default class index extends PureComponent {
    render() {
        const { num, title, diyHeader } = this.props
        return (
            <div className="dropdown-menu-header">
                {diyHeader ? diyHeader : <span>
                    {num ? num + ' 条新的' : '暂无新的'}
                    {title}

                </span>}
            </div>
        )
    }
}
