import React, { Component } from 'react'
import PropTypes from 'prop-types'
import form from '@/components/Form/form.module.scss'

export default class AddReceive extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        handelChange: PropTypes.func,
        county: PropTypes.bool
    }
    static defaultProps = {
        disabled: false,
        county: true,
        required: false,
        handelChange: () => { }

    }
    constructor(props) {
        super(props)
        const { value } = props
        const arr = (value || '').split(',')
        console.log(arr)
        this.state = {
            province: arr[0] || '',
            city: arr[1] || '',
            county: arr[2] || '',
            provinces: ['安徽', '澳门', '北京', '福建', '甘肃', '广东', '广西', '贵州', '海南', '河北', '河南', '黑龙江', '湖北', '湖南', '吉林', '江苏', '江西', '辽宁', '内蒙古', '宁夏', '青海', '山东', '山西', '陕西', '上海', '四川', '台湾', '天津', '西藏', '香港', '新疆', '云南', '浙江', '重庆', '其他'],
            cities: [],
            counties: []
        }
    }

    getCity(province) {
        var gc1 = [];
        gc1['安徽'] = ['合肥', '安庆', '蚌埠', '亳州', '巢湖', '池州', '滁州', '阜阳', '淮北', '淮南', '黄山', '六安', '马鞍山', '宿州', '铜陵', '芜湖', '宣城'];
        gc1['澳门'] = ['澳门'];
        gc1['北京'] = ['昌平', '朝阳区', '崇文', '大兴', '东城', '房山', '丰台', '海淀', '怀柔', '门头沟', '密云', '平谷', '石景山', '顺义', '通州区', '西城', '宣武', '延庆'];
        gc1['福建'] = ['福州', '龙岩', '南平', '宁德', '莆田', '泉州', '三明', '厦门', '漳州'];
        gc1['甘肃'] = ['兰州', '白银', '定西', '甘南', '嘉峪关', '金昌', '酒泉', '临夏', '陇南', '平凉', '庆阳', '天水', '武威', '张掖'];
        gc1['广东'] = ['广州', '潮州', '东莞', '佛山', '河源', '惠州', '江门', '揭阳', '茂名', '梅州', '清远', '汕头', '汕尾', '韶关', '深圳', '阳江', '云浮', '湛江', '肇庆', '中山', '珠海'];
        gc1['广西'] = ['桂林', '百色', '北海', '崇左', '防城港', '贵港', '河池', '贺州', '来宾', '柳州', '南宁', '钦州', '梧州', '玉林'];
        gc1['贵州'] = ['贵阳', '安顺', '毕节', '六盘水', '黔东南', '黔南', '黔西南', '铜仁', '遵义'];
        gc1['海南'] = ['海口', '白沙', '保亭', '昌江', '澄迈', '儋州', '定安', '东方', '乐东', '临高', '陵水', '南沙群岛', '琼海', '琼中', '三亚', '屯昌', '万宁', '文昌', '五指山', '西沙群岛', '中沙群岛'];
        gc1['河北'] = ['石家庄', '保定', '沧州', '承德', '邯郸', '衡水', '廊坊', '秦皇岛', '唐山', '邢台', '张家口'];
        gc1['河南'] = ['郑州', '安阳', '鹤壁', '焦作', '济源', '开封', '洛阳', '漯河', '南阳', '平顶山', '濮阳', '三门峡', '商丘', '新乡', '信阳', '许昌', '周口', '驻马店'];
        gc1['黑龙江'] = ['哈尔滨', '大庆', '大兴安岭', '鹤岗', '黑河', '鸡西', '佳木斯', '牡丹江', '七台河', '齐齐哈尔', '双鸭山', '绥化', '伊春'];
        gc1['湖北'] = ['武汉', '鄂州', '恩施', '黄冈', '黄石', '荆门', '荆州', '潜江', '神农架', '十堰', '随州', '天门', '仙桃', '咸宁', '襄樊', '孝感', '宜昌'];
        gc1['湖南'] = ['长沙', '常德', '郴州', '衡阳', '怀化', '娄底', '邵阳', '湘潭', '湘西', '益阳', '永州', '岳阳', '张家界', '株洲'];
        gc1['吉林'] = ['长春', '白城', '白山', '吉林', '辽源', '四平', '松原', '通化', '延边'];
        gc1['江苏'] = ['南京', '常州', '淮安', '连云港', '南通', '苏州', '宿迁', '泰州', '无锡', '徐州', '盐城', '扬州', '镇江'];
        gc1['江西'] = ['南昌', '抚州', '赣州', '吉安', '景德镇', '九江', '萍乡', '上饶', '新余', '宜春', '鹰潭'];
        gc1['辽宁'] = ['沈阳', '鞍山', '本溪', '朝阳市', '大连', '丹东', '抚顺', '阜新', '葫芦岛', '锦州', '辽阳', '盘锦', '铁岭', '营口'];
        gc1['内蒙古'] = ['呼和浩特', '阿拉善', '巴彦淖尔', '包头', '赤峰', '鄂尔多斯', '呼伦贝尔', '通辽', '乌海', '乌兰察布', '锡林郭勒', '兴安'];
        gc1['宁夏'] = ['银川', '固原', '石嘴山', '吴忠', '中卫'];
        gc1['青海'] = ['西宁', '果洛', '海北', '海东', '海南', '海西', '黄南', '玉树'];
        gc1['山东'] = ['济南', '滨州', '德州', '东营', '菏泽', '济宁', '莱芜', '聊城', '临沂', '青岛', '日照', '泰安', '威海', '潍坊', '烟台', '枣庄', '淄博'];
        gc1['山西'] = ['太原', '长治', '大同', '晋城', '晋中', '临汾', '吕梁', '朔州', '忻州', '阳泉', '运城'];
        gc1['陕西'] = ['西安', '安康', '宝鸡', '汉中', '商洛', '铜川', '渭南', '咸阳', '延安', '榆林'];
        gc1['上海'] = ['宝山', '长宁', '崇明', '奉贤', '虹口', '黄浦', '嘉定', '金山', '静安', '卢湾', '闵行', '南汇', '浦东', '普陀', '青浦', '松江', '徐汇', '杨浦', '闸北'];
        gc1['四川'] = ['成都', '阿坝', '巴中', '达州', '德阳', '甘孜', '广安', '广元', '乐山', '凉山', '泸州', '眉山', '绵阳', '内江', '南充', '攀枝花', '遂宁', '雅安', '宜宾', '资阳', '自贡'];
        gc1['台湾'] = ['台北', '阿莲', '安定', '安平', '八德', '八里', '白河', '白沙市', '板桥', '褒忠', '宝山市', '卑南', '北斗', '北港', '北门', '北埔', '北投', '补子', '布袋', '草屯', '长宾', '长治市', '潮州市', '车城', '成功', '城中区', '池上', '春日', '刺桐', '高雄', '花莲', '基隆', '嘉义', '苗栗', '南投', '屏东', '台东', '台南', '台中', '桃园', '新竹', '宜兰', '彰化'];
        gc1['天津'] = ['宝坻', '北辰', '大港', '东丽', '汉沽', '和平', '河北', '河东', '河西', '红桥', '蓟县', '津南', '静海', '南开', '宁河', '塘沽', '武清', '西青'];
        gc1['西藏'] = ['拉萨', '阿里', '昌都', '林芝', '那曲', '日喀则', '山南'];
        gc1['香港'] = ['北区', '大埔区', '东区', '观塘区', '黄大仙区', '九龙', '葵青区', '离岛区', '南区', '荃湾区', '沙田区', '深水?肚?', '屯门区', '湾仔区', '西贡区', '香港', '新界', '油尖旺区', '元朗区', '中西区'];
        gc1['新疆'] = ['乌鲁木齐', '阿克苏', '阿拉尔', '阿勒泰', '巴音郭楞', '博尔塔拉', '昌吉', '哈密', '和田', '喀什', '克拉玛依', '克孜勒苏柯尔克孜', '石河子', '塔城', '图木舒克', '吐鲁番', '五家渠', '伊犁'];
        gc1['云南'] = ['昆明', '保山', '楚雄', '大理', '德宏', '迪庆', '红河', '丽江', '临沧', '怒江', '曲靖', '思茅', '文山', '西双版纳', '玉溪', '昭通'];
        gc1['浙江'] = ['杭州', '湖州', '嘉兴', '金华', '丽水', '宁波', '衢州', '绍兴', '台州', '温州', '舟山'];
        gc1['重庆'] = ['巴南', '北碚', '璧山', '长寿', '城口', '大渡口', '大足', '垫江', '丰都', '奉节', '涪陵', '合川', '江北', '江津', '九龙坡', '开县', '梁平', '南岸', '南川', '彭水', '綦江', '黔江', '荣昌', '沙坪坝', '石柱', '双桥', '铜梁', '潼南', '万盛', '万州', '巫山', '巫溪', '武隆', '秀山', '永川', '酉阳', '渝北', '渝中', '云阳', '忠县'];
        gc1['其他'] = ['阿根廷', '埃及', '爱尔兰', '奥地利', '奥克兰', '澳大利亚', '巴基斯坦', '巴西', '保加利亚', '比利时', '冰岛', '朝鲜', '丹麦', '德国', '俄罗斯', '法国', '菲律宾', '芬兰', '哥伦比亚', '韩国', '荷兰', '加拿大', '柬埔寨', '喀麦隆', '老挝', '卢森堡', '罗马尼亚', '马达加斯加', '马来西亚', '毛里求斯', '美国', '秘鲁', '缅甸', '墨西哥', '南非', '尼泊尔', '挪威', '葡萄牙', '其它地区', '日本', '瑞典', '瑞士', '斯里兰卡', '泰国', '土耳其', '委内瑞拉', '文莱', '乌克兰', '西班牙', '希腊', '新加坡', '新西兰', '匈牙利', '以色列', '意大利', '印度', '印度尼西亚', '英国', '越南', '智利'];
        return gc1[province]
    }

    getCounty(province, city) {
        var gc2 = [];
        gc2['安徽'] = [];
        gc2['安徽']['合肥'] = ['长丰', '肥东', '肥西', '合肥市'];
        gc2['安徽']['安庆'] = ['安庆市', '枞阳', '怀宁', '潜山', '宿松', '太湖', '桐城', '望江', '岳西'];
        gc2['安徽']['蚌埠'] = ['蚌埠市', '固镇', '怀远', '五河'];
        gc2['安徽']['亳州'] = ['亳州市', '利辛', '蒙城', '涡阳'];
        gc2['安徽']['巢湖'] = ['巢湖市', '含山', '和县', '庐江', '无为'];
        gc2['安徽']['池州'] = ['池州市', '东至', '青阳', '石台'];
        gc2['安徽']['滁州'] = ['滁州市', '定远', '凤阳', '来安', '明光', '全椒', '天长'];
        gc2['安徽']['阜阳'] = ['阜南', '阜阳市', '界首', '临泉', '太和', '颍上'];
        gc2['安徽']['淮北'] = ['淮北市', '濉溪'];
        gc2['安徽']['淮南'] = ['凤台', '淮南市'];
        gc2['安徽']['黄山'] = ['黄山市', '祁门', '歙县', '休宁', '黟县'];
        gc2['安徽']['六安'] = ['霍邱', '霍山', '金寨', '六安市', '寿县', '舒城'];
        gc2['安徽']['马鞍山'] = ['当涂', '马鞍山市'];
        gc2['安徽']['宿州'] = ['砀山', '灵璧', '泗县', '宿州市', '萧县'];
        gc2['安徽']['铜陵'] = ['铜陵市', '铜陵县'];
        gc2['安徽']['芜湖'] = ['繁昌', '南陵', '芜湖市', '芜湖县'];
        gc2['安徽']['宣城'] = ['广德', '绩溪', '泾县', '旌德', '郎溪', '宁国', '宣城市'];
        gc2['澳门'] = [];
        gc2['澳门']['澳门'] = ['澳门'];
        gc2['北京'] = [];
        gc2['北京']['昌平'] = ['昌平'];
        gc2['北京']['朝阳区'] = ['朝阳区'];
        gc2['北京']['崇文'] = ['崇文'];
        gc2['北京']['大兴'] = ['大兴'];
        gc2['北京']['东城'] = ['东城'];
        gc2['北京']['房山'] = ['房山'];
        gc2['北京']['丰台'] = ['丰台'];
        gc2['北京']['海淀'] = ['海淀'];
        gc2['北京']['怀柔'] = ['怀柔'];
        gc2['北京']['门头沟'] = ['门头沟'];
        gc2['北京']['密云'] = ['密云'];
        gc2['北京']['平谷'] = ['平谷'];
        gc2['北京']['石景山'] = ['石景山'];
        gc2['北京']['顺义'] = ['顺义'];
        gc2['北京']['通州区'] = ['通州区'];
        gc2['北京']['西城'] = ['西城'];
        gc2['北京']['宣武'] = ['宣武'];
        gc2['北京']['延庆'] = ['延庆'];
        gc2['福建'] = [];
        gc2['福建']['福州'] = ['长乐', '福清', '福州市', '连江', '罗源', '闽侯', '闽清', '平潭', '永泰'];
        gc2['福建']['龙岩'] = ['长汀', '连城', '龙岩市', '上杭', '武平', '永定', '漳平'];
        gc2['福建']['南平'] = ['光泽', '建瓯', '建阳', '南平市', '浦城', '邵武', '顺昌', '松溪', '武夷山', '政和'];
        gc2['福建']['宁德'] = ['福安', '福鼎', '古田', '宁德市', '屏南', '寿宁', '霞浦', '柘荣', '周宁'];
        gc2['福建']['莆田'] = ['莆田市', '仙游'];
        gc2['福建']['泉州'] = ['安溪', '德化', '惠安', '金门', '晋江', '南安', '泉州市', '石狮', '永春'];
        gc2['福建']['三明'] = ['大田', '建宁', '将乐', '明溪', '宁化', '清流', '三明市', '沙县', '泰宁', '永安', '尤溪'];
        gc2['福建']['厦门'] = ['厦门市'];
        gc2['福建']['漳州'] = ['长泰', '东山', '华安', '龙海', '南靖', '平和', '云霄', '漳浦', '漳州市', '诏安'];
        gc2['甘肃'] = [];
        gc2['甘肃']['兰州'] = ['皋兰', '兰州市', '永登', '榆中'];
        gc2['甘肃']['白银'] = ['白银市', '会宁', '景泰', '靖远'];
        gc2['甘肃']['定西'] = ['定西市', '临洮', '陇西', '岷县', '通渭', '渭源', '漳县'];
        gc2['甘肃']['甘南'] = ['迭部', '合作', '临潭', '碌曲', '玛曲', '夏河', '舟曲', '卓尼'];
        gc2['甘肃']['嘉峪关'] = ['嘉峪关市'];
        gc2['甘肃']['金昌'] = ['金昌市', '永昌'];
        gc2['甘肃']['酒泉'] = ['阿克塞', '敦煌', '瓜州', '金塔', '酒泉市', '肃北', '玉门'];
        gc2['甘肃']['临夏'] = ['东乡族', '广河', '和政', '积石山', '康乐', '临夏市', '临夏县', '永靖'];
        gc2['甘肃']['陇南'] = ['成县', '宕昌', '徽县', '康县', '礼县', '两当', '陇南市', '文县', '西和'];
        gc2['甘肃']['平凉'] = ['崇信', '华亭', '泾川', '静宁', '灵台', '平凉市', '庄浪'];
        gc2['甘肃']['庆阳'] = ['合水', '华池', '环县', '宁县', '庆城', '庆阳市', '镇原', '正宁'];
        gc2['甘肃']['天水'] = ['甘谷', '秦安', '清水', '天水市', '武山', '张家川'];
        gc2['甘肃']['武威'] = ['古浪', '民勤', '天祝', '武威市'];
        gc2['甘肃']['张掖'] = ['高台', '临泽', '民乐', '山丹', '肃南', '张掖市'];
        gc2['广东'] = [];
        gc2['广东']['广州'] = ['从化', '广州市', '增城'];
        gc2['广东']['潮州'] = ['潮安', '潮州市', '饶平'];
        gc2['广东']['东莞'] = ['东莞'];
        gc2['广东']['佛山'] = ['佛山市'];
        gc2['广东']['河源'] = ['东源', '和平县', '河源市', '连平', '龙川', '紫金'];
        gc2['广东']['惠州'] = ['博罗', '惠东', '惠州市', '龙门'];
        gc2['广东']['江门'] = ['恩平', '鹤山', '江门市', '开平', '台山'];
        gc2['广东']['揭阳'] = ['惠来', '揭东', '揭西', '揭阳市', '普宁'];
        gc2['广东']['茂名'] = ['电白', '高州', '化州', '茂名市', '信宜'];
        gc2['广东']['梅州'] = ['大埔', '丰顺', '蕉岭', '梅县', '梅州市', '平远', '五华', '兴宁'];
        gc2['广东']['清远'] = ['佛冈', '连南', '连山', '连州', '清新', '清远市', '阳山', '英德'];
        gc2['广东']['汕头'] = ['南澳', '汕头市'];
        gc2['广东']['汕尾'] = ['海丰', '陆丰', '陆河', '汕尾市'];
        gc2['广东']['韶关'] = ['乐昌', '南雄', '仁化', '乳源', '韶关市', '始兴', '翁源', '新丰'];
        gc2['广东']['深圳'] = ['深圳市'];
        gc2['广东']['阳江'] = ['阳春', '阳东', '阳江市', '阳西'];
        gc2['广东']['云浮'] = ['罗定', '新兴', '郁南', '云安', '云浮市'];
        gc2['广东']['湛江'] = ['雷州', '廉江', '遂溪', '吴川', '徐闻', '湛江市'];
        gc2['广东']['肇庆'] = ['德庆', '封开', '高要', '广宁', '怀集', '四会', '肇庆市'];
        gc2['广东']['中山'] = ['中山市'];
        gc2['广东']['珠海'] = ['珠海市'];
        gc2['广西'] = [];
        gc2['广西']['桂林'] = ['恭城', '灌阳', '桂林市', '荔浦', '临桂', '灵川', '龙胜', '平乐', '全州', '兴安', '阳朔', '永福', '资源'];
        gc2['广西']['百色'] = ['百色市', '德保', '靖西', '乐业', '凌云', '隆林', '那坡', '平果', '田东', '田林', '田阳', '西林'];
        gc2['广西']['北海'] = ['北海市', '合浦'];
        gc2['广西']['崇左'] = ['崇左市', '大新', '扶绥', '龙州', '宁明', '凭祥', '天等'];
        gc2['广西']['防城港'] = ['东兴', '防城港市', '上思'];
        gc2['广西']['贵港'] = ['贵港市', '桂平', '平南'];
        gc2['广西']['河池'] = ['巴马', '大化', '东兰', '都安', '凤山', '河池市', '环江', '罗城', '南丹', '天峨', '宜州'];
        gc2['广西']['贺州'] = ['富川', '贺州市', '昭平', '钟山'];
        gc2['广西']['来宾'] = ['合山', '金秀', '来宾市', '武宣', '象州', '忻城'];
        gc2['广西']['柳州'] = ['柳城', '柳江', '柳州市', '鹿寨', '融安', '融水', '三江'];
        gc2['广西']['南宁'] = ['宾阳', '横县', '隆安', '马山', '南宁市', '上林', '武鸣'];
        gc2['广西']['钦州'] = ['灵山', '浦北', '钦州市'];
        gc2['广西']['梧州'] = ['苍梧', '岑溪', '蒙山', '藤县', '梧州市'];
        gc2['广西']['玉林'] = ['北流', '博白', '陆川', '容县', '兴业', '玉林市'];
        gc2['贵州'] = [];
        gc2['贵州']['贵阳'] = ['贵阳市', '开阳', '清镇', '息烽', '修文'];
        gc2['贵州']['安顺'] = ['安顺市', '关岭', '平坝', '普定', '镇宁', '紫云'];
        gc2['贵州']['毕节'] = ['毕节市', '大方', '赫章', '金沙', '纳雍', '黔西', '威宁', '织金'];
        gc2['贵州']['六盘水'] = ['六盘水市', '六枝', '盘县', '水城'];
        gc2['贵州']['黔东南'] = ['岑巩', '从江', '丹寨', '黄平', '剑河', '锦屏', '凯里', '雷山', '黎平', '麻江', '榕江', '三穗', '施秉', '台江', '天柱', '镇远'];
        gc2['贵州']['黔南'] = ['长顺', '都匀', '独山', '福泉', '贵定', '惠水', '荔波', '龙里', '罗甸', '平塘', '三都', '瓮安'];
        gc2['贵州']['黔西南'] = ['安龙', '册亨', '普安', '晴隆', '望谟', '兴仁', '兴义', '贞丰'];
        gc2['贵州']['铜仁'] = ['德江', '江口', '石阡', '思南', '松桃', '铜仁市', '万山', '沿河', '印江', '玉屏'];
        gc2['贵州']['遵义'] = ['赤水', '道真', '凤冈', '湄潭', '仁怀', '绥阳', '桐梓', '务川', '习水', '余庆', '正安', '遵义市', '遵义县'];
        gc2['海南'] = [];
        gc2['海南']['海口'] = ['海口'];
        gc2['海南']['白沙'] = ['白沙'];
        gc2['海南']['保亭'] = ['保亭'];
        gc2['海南']['昌江'] = ['昌江'];
        gc2['海南']['澄迈'] = ['澄迈'];
        gc2['海南']['儋州'] = ['儋州'];
        gc2['海南']['定安'] = ['定安'];
        gc2['海南']['东方'] = ['东方'];
        gc2['海南']['乐东'] = ['乐东'];
        gc2['海南']['临高'] = ['临高'];
        gc2['海南']['陵水'] = ['陵水'];
        gc2['海南']['南沙群岛'] = ['南沙群岛'];
        gc2['海南']['琼海'] = ['琼海'];
        gc2['海南']['琼中'] = ['琼中'];
        gc2['海南']['三亚'] = ['三亚'];
        gc2['海南']['屯昌'] = ['屯昌'];
        gc2['海南']['万宁'] = ['万宁'];
        gc2['海南']['文昌'] = ['文昌'];
        gc2['海南']['五指山'] = ['五指山'];
        gc2['海南']['西沙群岛'] = ['西沙群岛'];
        gc2['海南']['中沙群岛'] = ['中沙群岛'];
        gc2['河北'] = [];
        gc2['河北']['石家庄'] = ['藁城', '晋州', '井陉', '灵寿', '鹿泉', '栾城', '平山', '深泽', '石家庄市', '无极', '辛集', '新乐', '行唐', '元氏', '赞皇', '赵县', '正定', '高邑'];
        gc2['河北']['保定'] = ['安国', '安新', '保定市', '博野', '定兴', '定州', '阜平', '高碑店', '高阳', '涞水', '涞源', '蠡县', '满城', '清苑', '曲阳', '容城', '顺平', '唐县', '望都', '雄县', '徐水', '易县', '涿州'];
        gc2['河北']['沧州'] = ['泊头', '沧县', '沧州市', '东光', '海兴', '河间', '黄骅', '孟村', '南皮', '青县', '任丘', '肃宁', '吴桥', '献县', '盐山'];
        gc2['河北']['承德'] = ['承德市', '承德县', '丰宁', '宽城', '隆化', '滦平', '平泉', '围场', '兴隆'];
        gc2['河北']['邯郸'] = ['成安', '磁县', '大名', '肥乡', '馆陶', '广平', '邯郸市', '邯郸县', '鸡泽', '临漳', '邱县', '曲周', '涉县', '魏县', '武安', '永年'];
        gc2['河北']['衡水'] = ['安平', '阜城', '故城', '衡水市', '冀州', '景县', '饶阳', '深州', '武强', '武邑', '枣强'];
        gc2['河北']['廊坊'] = ['霸州', '大厂', '大城', '固安', '廊坊市', '三河', '文安', '香河', '永清'];
        gc2['河北']['秦皇岛'] = ['昌黎', '抚宁', '卢龙', '秦皇岛市', '青龙'];
        gc2['河北']['唐山'] = ['乐亭', '滦南', '滦县', '迁安', '迁西', '唐海', '唐山市', '玉田', '遵化'];
        gc2['河北']['邢台'] = ['柏乡', '广宗', '巨鹿', '临城', '临西', '隆尧', '内丘', '南宫', '南和', '宁晋', '平乡', '清河', '任县', '沙河', '威县', '新河', '邢台市', '邢台县'];
        gc2['河北']['张家口'] = ['赤城', '崇礼', '沽源', '怀安', '怀来', '康保', '尚义', '万全', '蔚县', '宣化', '阳原', '张北', '张家口市', '涿鹿'];
        gc2['河南'] = [];
        gc2['河南']['郑州'] = ['登封', '巩义', '新密', '新郑', '荥阳', '郑州市', '中牟'];
        gc2['河南']['安阳'] = ['安阳市', '安阳县', '滑县', '林州', '内黄', '汤阴'];
        gc2['河南']['鹤壁'] = ['鹤壁市', '浚县', '淇县'];
        gc2['河南']['焦作'] = ['博爱', '焦作市', '孟州', '沁阳', '温县', '武陟', '修武'];
        gc2['河南']['济源'] = ['济源市'];
        gc2['河南']['开封'] = ['开封市', '开封县', '兰考', '杞县', '通许', '尉氏'];
        gc2['河南']['洛阳'] = ['栾川', '洛宁', '洛阳市', '孟津', '汝阳', '嵩县', '新安', '偃师', '伊川', '宜阳'];
        gc2['河南']['漯河'] = ['临颍', '漯河市', '舞阳'];
        gc2['河南']['南阳'] = ['邓州', '方城', '内乡', '南阳市', '南召', '社旗', '唐河', '桐柏', '西峡', '淅川', '新野', '镇平'];
        gc2['河南']['平顶山'] = ['宝丰', '郏县', '鲁山', '平顶山市', '汝州', '舞钢', '叶县'];
        gc2['河南']['濮阳'] = ['范县', '南乐', '濮阳市', '濮阳县', '清丰', '台前'];
        gc2['河南']['三门峡'] = ['灵宝', '卢氏', '三门峡市', '陕县', '渑池', '义马'];
        gc2['河南']['商丘'] = ['民权', '宁陵', '商丘市', '睢县', '夏邑', '永城', '虞城', '柘城'];
        gc2['河南']['新乡'] = ['长垣', '封丘', '辉县', '获嘉', '卫辉', '新乡市', '新乡县', '延津', '原阳'];
        gc2['河南']['信阳'] = ['固始', '光山', '淮滨', '潢川', '罗山', '商城', '息县', '新县', '信阳市'];
        gc2['河南']['许昌'] = ['长葛', '襄城', '许昌市', '许昌县', '鄢陵', '禹州'];
        gc2['河南']['周口'] = ['郸城', '扶沟', '淮阳', '鹿邑', '商水', '沈丘', '太康', '西华', '项城', '周口市'];
        gc2['河南']['驻马店'] = ['泌阳', '平舆', '确山', '汝南', '上蔡', '遂平', '西平', '新蔡', '正阳', '驻马店市'];
        gc2['黑龙江'] = [];
        gc2['黑龙江']['哈尔滨'] = ['巴彦', '宾县', '方正', '哈尔滨市', '木兰', '尚志', '双城', '通河', '五常', '延寿', '依兰'];
        gc2['黑龙江']['大庆'] = ['大庆市', '杜尔伯特', '林甸', '肇源', '肇州'];
        gc2['黑龙江']['大兴安岭'] = ['呼玛', '呼中', '加格达奇', '漠河', '松岭', '塔河', '新林'];
        gc2['黑龙江']['鹤岗'] = ['鹤岗市', '萝北', '绥滨'];
        gc2['黑龙江']['黑河'] = ['北安', '黑河市', '嫩江', '孙吴', '五大连池', '逊克'];
        gc2['黑龙江']['鸡西'] = ['虎林', '鸡东', '鸡西市', '密山'];
        gc2['黑龙江']['佳木斯'] = ['抚远', '富锦', '桦川', '桦南', '佳木斯市', '汤原', '同江'];
        gc2['黑龙江']['牡丹江'] = ['东宁', '海林', '林口', '牡丹江市', '穆棱', '宁安', '绥芬河'];
        gc2['黑龙江']['七台河'] = ['勃利', '七台河'];
        gc2['黑龙江']['齐齐哈尔'] = ['拜泉', '富裕', '甘南', '克东', '克山', '龙江', '讷河', '齐齐哈尔市', '泰来', '依安'];
        gc2['黑龙江']['双鸭山'] = ['宝清', '集贤', '饶河', '双鸭山市', '友谊'];
        gc2['黑龙江']['绥化'] = ['安达', '海伦', '兰西', '明水', '青冈', '庆安', '绥化市', '绥棱', '望奎', '肇东'];
        gc2['黑龙江']['伊春'] = ['嘉荫', '铁力', '伊春市'];
        gc2['湖北'] = [];
        gc2['湖北']['武汉'] = ['武汉市'];
        gc2['湖北']['鄂州'] = ['鄂州市'];
        gc2['湖北']['恩施'] = ['巴东', '恩施市', '鹤峰', '建始', '来凤', '利川', '咸丰', '宣恩'];
        gc2['湖北']['黄冈'] = ['红安', '黄冈市', '黄梅', '罗田', '麻城', '蕲春', '团风', '武穴', '浠水', '英山'];
        gc2['湖北']['黄石'] = ['大冶', '黄石', '阳新'];
        gc2['湖北']['荆门'] = ['京山', '荆门市', '沙洋', '钟祥'];
        gc2['湖北']['荆州'] = ['公安', '洪湖', '监利', '江陵', '荆州市', '石首', '松滋'];
        gc2['湖北']['潜江'] = ['潜江'];
        gc2['湖北']['神农架'] = ['神农架'];
        gc2['湖北']['十堰'] = ['丹江口', '房县', '十堰市', '郧西', '郧县', '竹山', '竹溪'];
        gc2['湖北']['随州'] = ['广水', '随州市'];
        gc2['湖北']['天门'] = ['天门市'];
        gc2['湖北']['仙桃'] = ['仙桃市'];
        gc2['湖北']['咸宁'] = ['赤壁', '崇阳', '嘉鱼', '通城', '通山', '咸宁市'];
        gc2['湖北']['襄樊'] = ['保康', '谷城', '老河口', '南漳', '襄樊市', '宜城', '枣阳'];
        gc2['湖北']['孝感'] = ['安陆', '大悟', '汉川', '孝昌', '孝感市', '应城', '云梦'];
        gc2['湖北']['宜昌'] = ['长阳', '当阳', '五峰', '兴山', '宜昌市', '宜都', '远安', '枝江', '秭归'];
        gc2['湖南'] = [];
        gc2['湖南']['长沙'] = ['长沙市', '长沙县', '浏阳', '宁乡', '望城'];
        gc2['湖南']['常德'] = ['安乡', '常德市', '汉寿', '津市', '澧县', '临澧', '石门', '桃源'];
        gc2['湖南']['郴州'] = ['安仁', '郴州市', '桂东', '桂阳', '嘉禾', '临武', '汝城', '宜章', '永兴', '资兴'];
        gc2['湖南']['衡阳'] = ['常宁', '衡东', '衡南', '衡山', '衡阳市', '衡阳县', '耒阳', '祁东'];
        gc2['湖南']['怀化'] = ['辰溪', '洪江', '怀化市', '会同', '靖州', '麻阳', '通道', '新晃', '溆浦', '沅陵', '芷江', '中方'];
        gc2['湖南']['娄底'] = ['冷水江', '涟源', '娄底市', '双峰', '新化'];
        gc2['湖南']['邵阳'] = ['城步', '洞口', '隆回', '邵东', '邵阳市', '邵阳县', '绥宁', '武冈', '新宁', '新邵'];
        gc2['湖南']['湘潭'] = ['韶山', '湘潭市', '湘潭县', '湘乡'];
        gc2['湖南']['湘西'] = ['保靖', '凤凰', '古丈', '花垣', '吉首', '龙山', '泸溪', '永顺'];
        gc2['湖南']['益阳'] = ['安化', '南县', '桃江', '益阳市', '沅江'];
        gc2['湖南']['永州'] = ['道县', '东安', '江华', '江永', '蓝山', '宁远', '祁阳', '双牌', '新田', '永州市'];
        gc2['湖南']['岳阳'] = ['华容', '临湘', '汨罗', '平江', '湘阴', '岳阳市', '岳阳县'];
        gc2['湖南']['张家界'] = ['慈利', '桑植', '张家界市'];
        gc2['湖南']['株洲'] = ['茶陵', '醴陵', '炎陵', '攸县', '株洲市', '株洲县'];
        gc2['吉林'] = [];
        gc2['吉林']['长春'] = ['长春市', '德惠', '九台', '农安', '榆树'];
        gc2['吉林']['白城'] = ['白城市', '大安', '洮南', '通榆', '镇赉'];
        gc2['吉林']['白山'] = ['白山', '长白', '抚松', '靖宇', '临江'];
        gc2['吉林']['吉林'] = ['桦甸', '吉林市', '蛟河', '磐石', '舒兰', '永吉'];
        gc2['吉林']['辽源'] = ['东丰', '东辽', '辽源市'];
        gc2['吉林']['四平'] = ['公主岭', '梨树', '双辽', '四平市', '伊通'];
        gc2['吉林']['松原'] = ['长岭', '扶余', '前郭尔罗斯', '乾安', '松原市'];
        gc2['吉林']['通化'] = ['辉南', '集安', '柳河', '梅河口', '通化市', '通化县'];
        gc2['吉林']['延边'] = ['延吉市', '敦化', '和龙', '珲春', '龙井', '图们', '汪清', '安图'];
        gc2['江苏'] = [];
        gc2['江苏']['南京'] = ['高淳', '溧水', '南京市'];
        gc2['江苏']['常州'] = ['常州市', '金坛', '溧阳'];
        gc2['江苏']['淮安'] = ['洪泽', '淮安市', '金湖', '涟水', '盱眙'];
        gc2['江苏']['连云港'] = ['东海', '赣榆', '灌南', '灌云', '连云港市'];
        gc2['江苏']['南通'] = ['海安', '海门', '南通市', '启东', '如东', '如皋', '通州'];
        gc2['江苏']['苏州'] = ['常熟', '昆山', '苏州市', '太仓', '吴江', '张家港'];
        gc2['江苏']['宿迁'] = ['沭阳', '泗洪', '泗阳', '宿迁市'];
        gc2['江苏']['泰州'] = ['姜堰', '靖江', '泰兴', '泰州市', '兴化'];
        gc2['江苏']['无锡'] = ['江阴', '无锡市', '宜兴'];
        gc2['江苏']['徐州'] = ['丰县', '沛县', '邳州', '睢宁', '铜山', '新沂', '徐州市'];
        gc2['江苏']['盐城'] = ['滨海', '大丰', '东台', '阜宁', '建湖', '射阳', '响水', '盐城市'];
        gc2['江苏']['扬州'] = ['宝应', '高邮', '江都', '扬州市', '仪征'];
        gc2['江苏']['镇江'] = ['丹阳', '句容', '扬中', '镇江市'];
        gc2['江西'] = [];
        gc2['江西']['南昌'] = ['安义', '进贤', '南昌市', '南昌县', '新建'];
        gc2['江西']['抚州'] = ['崇仁', '东乡', '抚州市', '广昌', '金溪', '乐安', '黎川', '南城', '南丰', '宜黄', '资溪'];
        gc2['江西']['赣州'] = ['安远', '崇义', '大余', '定南', '赣县', '赣州市', '会昌', '龙南', '南康', '宁都', '全南', '瑞金', '上犹', '石城', '信丰', '兴国', '寻乌', '于都'];
        gc2['江西']['吉安'] = ['安福', '吉安市', '吉安县', '吉水', '井冈山', '遂川', '泰和', '万安', '峡江', '新干', '永丰', '永新'];
        gc2['江西']['景德镇'] = ['浮梁', '景德镇市', '乐平'];
        gc2['江西']['九江'] = ['德安', '都昌', '湖口', '九江市', '九江县', '彭泽', '瑞昌', '武宁', '星子', '修水', '永修'];
        gc2['江西']['萍乡'] = ['莲花', '芦溪', '萍乡市', '上栗'];
        gc2['江西']['上饶'] = ['德兴', '广丰', '横峰', '鄱阳', '铅山', '上饶市', '上饶县', '万年', '婺源', '弋阳', '余干', '玉山'];
        gc2['江西']['新余'] = ['分宜', '新余市'];
        gc2['江西']['宜春'] = ['丰城', '奉新', '高安', '靖安', '上高', '铜鼓', '万载', '宜春市', '宜丰', '樟树'];
        gc2['江西']['鹰潭'] = ['贵溪', '鹰潭市', '余江'];
        gc2['辽宁'] = [];
        gc2['辽宁']['沈阳'] = ['法库', '康平', '辽中', '沈阳市', '新民'];
        gc2['辽宁']['鞍山'] = ['鞍山市', '海城', '台安', '岫岩'];
        gc2['辽宁']['本溪'] = ['本溪市', '本溪县', '桓仁'];
        gc2['辽宁']['朝阳市'] = ['北票', '朝阳市', '朝阳县', '建平', '喀喇沁左翼', '凌源'];
        gc2['辽宁']['大连'] = ['长海', '大连市', '普兰店', '瓦房店', '庄河'];
        gc2['辽宁']['丹东'] = ['丹东市', '东港', '凤城', '宽甸'];
        gc2['辽宁']['抚顺'] = ['抚顺市', '抚顺县', '清原', '新宾'];
        gc2['辽宁']['阜新'] = ['阜新市', '阜新县', '彰武'];
        gc2['辽宁']['葫芦岛'] = ['葫芦岛市', '建昌', '绥中', '兴城'];
        gc2['辽宁']['锦州'] = ['北镇', '黑山', '锦州市', '凌海', '义县'];
        gc2['辽宁']['辽阳'] = ['灯塔', '辽阳市', '辽阳县'];
        gc2['辽宁']['盘锦'] = ['大洼', '盘锦市', '盘山'];
        gc2['辽宁']['铁岭'] = ['昌图', '调兵山', '开原', '铁岭市', '铁岭县', '西丰'];
        gc2['辽宁']['营口'] = ['大石桥', '盖州', '营口市'];
        gc2['内蒙古'] = [];
        gc2['内蒙古']['呼和浩特'] = ['和林格尔', '呼和浩特市', '清水河', '土默特左旗', '托克托', '武川'];
        gc2['内蒙古']['阿拉善'] = ['阿拉善右旗', '阿拉善左旗', '额济纳旗'];
        gc2['内蒙古']['巴彦淖尔'] = ['巴彦淖尔市', '磴口', '杭锦后旗', '乌拉特后旗', '乌拉特前旗', '乌拉特中旗', '五原'];
        gc2['内蒙古']['包头'] = ['包头市', '达尔罕茂明安联合旗', '固阳', '土默特右旗'];
        gc2['内蒙古']['赤峰'] = ['阿鲁科尔沁旗', '敖汉旗', '巴林右旗', '巴林左旗', '赤峰市', '喀喇沁旗', '克什克腾旗', '林西', '宁城', '翁牛特旗'];
        gc2['内蒙古']['鄂尔多斯'] = ['达拉特旗', '鄂尔多斯市', '鄂托克旗', '鄂托克前旗', '杭锦旗', '乌审旗', '伊金霍洛旗', '准格尔旗'];
        gc2['内蒙古']['呼伦贝尔'] = ['阿荣旗', '陈巴尔虎旗', '额尔古纳', '鄂伦春旗', '鄂温克族旗', '根河', '呼伦贝尔市', '满洲里', '莫力达瓦旗', '新巴尔虎右旗', '新巴尔虎左旗', '牙克石', '扎兰屯'];
        gc2['内蒙古']['通辽'] = ['霍林郭勒', '开鲁', '科尔沁左翼后旗', '科尔沁左翼中旗', '库伦旗', '奈曼旗', '通辽市', '扎鲁特旗'];
        gc2['内蒙古']['乌海'] = ['乌海'];
        gc2['内蒙古']['乌兰察布'] = ['察哈尔右翼后旗', '察哈尔右翼前旗', '察哈尔右翼中旗', '丰镇', '化德', '凉城', '商都', '四子王旗', '乌兰察布市', '兴和', '卓资'];
        gc2['内蒙古']['锡林郭勒'] = ['阿巴嘎旗', '东乌珠穆沁旗', '多伦', '二连浩特', '苏尼特右旗', '苏尼特左旗', '太仆寺旗', '西乌珠穆沁旗', '锡林郭勒市', '镶黄旗', '正蓝旗', '正镶白旗'];
        gc2['内蒙古']['兴安'] = ['阿尔山', '科尔沁右翼前旗', '科尔沁右翼中旗', '突泉', '乌兰浩特', '扎赉特旗'];
        gc2['宁夏'] = [];
        gc2['宁夏']['银川'] = ['贺兰', '灵武', '银川市', '永宁'];
        gc2['宁夏']['固原'] = ['固原市', '泾源', '隆德', '彭阳', '西吉'];
        gc2['宁夏']['石嘴山'] = ['平罗', '石嘴山市'];
        gc2['宁夏']['吴忠'] = ['青铜峡', '同心', '吴忠市', '盐池'];
        gc2['宁夏']['中卫'] = ['海原', '中宁', '中卫市'];
        gc2['青海'] = [];
        gc2['青海']['西宁'] = ['大通', '湟源', '湟中', '西宁市'];
        gc2['青海']['果洛'] = ['班玛', '达日', '甘德', '久治', '玛多', '玛沁'];
        gc2['青海']['海北'] = ['刚察', '海晏', '门源', '祁连'];
        gc2['青海']['海东'] = ['互助', '化隆', '乐都', '民和', '平安', '循化'];
        gc2['青海']['海南'] = ['共和', '贵德', '贵南', '同德', '兴海'];
        gc2['青海']['海西'] = ['大柴旦', '德令哈', '都兰', '格尔木', '冷湖', '茫崖', '天峻', '乌兰'];
        gc2['青海']['黄南'] = ['河南', '尖扎', '同仁', '泽库'];
        gc2['青海']['玉树'] = ['称多', '囊谦', '曲麻莱', '玉树', '杂多', '治多'];
        gc2['山东'] = [];
        gc2['山东']['济南'] = ['济南市', '济阳', '平阴', '商河', '章丘'];
        gc2['山东']['滨州'] = ['滨州市', '博兴', '惠民', '无棣', '阳信', '沾化', '邹平'];
        gc2['山东']['德州'] = ['德州市', '乐陵', '临邑', '陵县', '宁津', '平原', '齐河', '庆云', '武城', '夏津', '禹城'];
        gc2['山东']['东营'] = ['东营市', '广饶', '垦利', '利津'];
        gc2['山东']['菏泽'] = ['曹县', '成武', '单县', '定陶', '东明', '菏泽市', '巨野', '鄄城', '郓城'];
        gc2['山东']['济宁'] = ['济宁市', '嘉祥', '金乡', '梁山', '曲阜', '泗水', '微山', '汶上', '兖州', '鱼台', '邹城'];
        gc2['山东']['莱芜'] = ['莱芜市'];
        gc2['山东']['聊城'] = ['茌平', '东阿', '高唐', '冠县', '聊城市', '临清', '莘县', '阳谷'];
        gc2['山东']['临沂'] = ['苍山', '费县', '莒南', '临沭', '临沂市', '蒙阴', '平邑', '郯城', '沂南', '沂水'];
        gc2['山东']['青岛'] = ['即墨', '胶南', '胶州', '莱西', '平度', '青岛市'];
        gc2['山东']['日照'] = ['莒县', '日照市', '五莲'];
        gc2['山东']['泰安'] = ['东平', '肥城', '宁阳', '泰安市', '新泰'];
        gc2['山东']['威海'] = ['荣成', '乳山', '威海市', '文登'];
        gc2['山东']['潍坊'] = ['安丘', '昌乐', '昌邑', '高密', '临朐', '青州', '寿光', '潍坊市', '诸城'];
        gc2['山东']['烟台'] = ['长岛', '海阳', '莱阳', '莱州', '龙口', '蓬莱', '栖霞', '烟台市', '招远'];
        gc2['山东']['枣庄'] = ['滕州', '枣庄市'];
        gc2['山东']['淄博'] = ['高青', '桓台', '沂源', '淄博市'];
        gc2['山西'] = [];
        gc2['山西']['太原'] = ['古交', '娄烦', '清徐', '太原市', '阳曲'];
        gc2['山西']['长治'] = ['长治市', '长治县', '长子', '壶关', '黎城', '潞城', '平顺', '沁县', '沁源', '屯留', '武乡', '襄垣'];
        gc2['山西']['大同'] = ['大同市', '大同县', '广灵', '浑源', '灵丘', '天镇', '阳高', '左云'];
        gc2['山西']['晋城'] = ['高平', '晋城市', '陵川', '沁水', '阳城', '泽州'];
        gc2['山西']['晋中'] = ['和顺', '介休', '晋中市', '灵石', '平遥', '祁县', '寿阳', '太谷', '昔阳', '榆社', '左权'];
        gc2['山西']['临汾'] = ['安泽', '大宁', '汾西', '浮山', '古县', '洪洞', '侯马', '霍州', '吉县', '临汾市', '蒲县', '曲沃', '隰县', '乡宁', '襄汾', '翼城', '永和'];
        gc2['山西']['吕梁'] = ['方山', '汾阳', '交城', '交口', '岚县', '临县', '柳林', '吕梁市', '石楼', '文水', '孝义', '兴县', '中阳'];
        gc2['山西']['朔州'] = ['怀仁', '山阴', '朔州市', '应县', '右玉'];
        gc2['山西']['忻州'] = ['保德', '代县', '定襄', '繁峙', '河曲', '静乐', '岢岚', '宁武', '偏关', '神池', '五台', '五寨', '忻州市', '原平'];
        gc2['山西']['阳泉'] = ['平定', '阳泉市', '盂县'];
        gc2['山西']['运城'] = ['河津', '稷山', '绛县', '临猗', '平陆', '芮城', '万荣', '闻喜', '夏县', '新绛', '永济', '垣曲', '运城市'];
        gc2['陕西'] = [];
        gc2['陕西']['西安'] = ['高陵', '户县', '蓝田', '西安市', '周至'];
        gc2['陕西']['安康'] = ['安康市', '白河', '汉阴', '岚皋', '宁陕', '平利', '石泉', '旬阳', '镇坪', '紫阳'];
        gc2['陕西']['宝鸡'] = ['宝鸡市', '凤县', '凤翔', '扶风', '麟游', '陇县', '眉县', '岐山', '千阳', '太白'];
        gc2['陕西']['汉中'] = ['城固', '佛坪', '汉中市', '留坝', '略阳', '勉县', '南郑', '宁强', '西乡', '洋县', '镇巴'];
        gc2['陕西']['商洛'] = ['丹凤', '洛南', '山阳', '商洛市', '商南', '镇安', '柞水'];
        gc2['陕西']['铜川'] = ['铜川市', '宜君'];
        gc2['陕西']['渭南'] = ['白水', '澄城', '大荔', '富平', '韩城', '合阳', '华县', '华阴', '蒲城', '潼关', '渭南市'];
        gc2['陕西']['咸阳'] = ['彬县', '长武', '淳化', '泾阳', '礼泉', '乾县', '三原', '武功', '咸阳市', '兴平', '旬邑', '永寿'];
        gc2['陕西']['延安'] = ['安塞', '富县', '甘泉', '黄陵', '黄龙', '洛川', '吴起', '延安市', '延长', '延川', '宜川', '志丹', '子长'];
        gc2['陕西']['榆林'] = ['定边', '府谷', '横山', '佳县', '靖边', '米脂', '清涧', '神木', '绥德', '吴堡', '榆林市', '子洲'];
        gc2['上海'] = [];
        gc2['上海']['宝山'] = ['宝山'];
        gc2['上海']['长宁'] = ['长宁'];
        gc2['上海']['崇明'] = ['崇明'];
        gc2['上海']['奉贤'] = ['奉贤'];
        gc2['上海']['虹口'] = ['虹口'];
        gc2['上海']['黄浦'] = ['黄浦'];
        gc2['上海']['嘉定'] = ['嘉定'];
        gc2['上海']['金山'] = ['金山'];
        gc2['上海']['静安'] = ['静安'];
        gc2['上海']['卢湾'] = ['卢湾'];
        gc2['上海']['闵行'] = ['闵行'];
        gc2['上海']['南汇'] = ['南汇'];
        gc2['上海']['浦东'] = ['浦东'];
        gc2['上海']['普陀'] = ['普陀'];
        gc2['上海']['青浦'] = ['青浦'];
        gc2['上海']['松江'] = ['松江'];
        gc2['上海']['徐汇'] = ['徐汇'];
        gc2['上海']['杨浦'] = ['杨浦'];
        gc2['上海']['闸北'] = ['闸北'];
        gc2['四川'] = [];
        gc2['四川']['成都'] = ['成都市', '崇州', '大邑', '都江堰', '金堂', '彭州', '郫县', '蒲江', '邛崃', '双流', '新津'];
        gc2['四川']['阿坝'] = ['阿坝', '黑水', '红原', '金川', '九寨沟', '理县', '马尔康', '茂县', '壤塘', '若尔盖', '松潘', '汶川', '小金'];
        gc2['四川']['巴中'] = ['巴中市', '南江', '平昌', '通江'];
        gc2['四川']['达州'] = ['达县', '达州市', '大竹', '开江', '渠县', '万源', '宣汉'];
        gc2['四川']['德阳'] = ['德阳市', '广汉', '罗江', '绵竹', '什邡', '中江'];
        gc2['四川']['甘孜'] = ['巴塘', '白玉', '丹巴', '道孚', '稻城', '得荣', '德格', '甘孜', '九龙', '康定', '理塘', '泸定', '炉霍', '色达', '石渠', '乡城', '新龙', '雅江'];
        gc2['四川']['广安'] = ['广安市', '华蓥', '邻水', '武胜', '岳池'];
        gc2['四川']['广元'] = ['苍溪', '广元市', '剑阁', '青川', '旺苍'];
        gc2['四川']['乐山'] = ['峨边', '峨眉山', '夹江', '犍为', '井研', '乐山市', '马边', '沐川'];
        gc2['四川']['凉山'] = ['布拖', '德昌', '甘洛', '会东', '会理', '金阳', '雷波', '美姑', '冕宁', '木里', '宁南', '普格', '西昌', '喜德', '盐源', '越西', '昭觉'];
        gc2['四川']['泸州'] = ['古蔺', '合江', '泸县', '泸州市', '叙永'];
        gc2['四川']['眉山'] = ['丹棱', '洪雅', '眉山市', '彭山', '青神', '仁寿'];
        gc2['四川']['绵阳'] = ['安县', '北川', '江油', '绵阳市', '平武', '三台', '盐亭', '梓潼'];
        gc2['四川']['内江'] = ['隆昌', '内江市', '威远', '资中'];
        gc2['四川']['南充'] = ['阆中', '南部', '南充市', '蓬安', '西充', '仪陇', '营山'];
        gc2['四川']['攀枝花'] = ['米易', '攀枝花市', '盐边'];
        gc2['四川']['遂宁'] = ['大英', '蓬溪', '射洪', '遂宁市'];
        gc2['四川']['雅安'] = ['宝兴', '汉源', '芦山', '名山', '石棉', '天全', '雅安市', '荥经'];
        gc2['四川']['宜宾'] = ['长宁县', '高县', '珙县', '江安', '筠连', '南溪', '屏山', '兴文', '宜宾市', '宜宾县'];
        gc2['四川']['资阳'] = ['安岳', '简阳', '乐至', '资阳市'];
        gc2['四川']['自贡'] = ['富顺', '荣县', '自贡市'];
        gc2['台湾'] = [];
        gc2['台湾']['台北'] = ['台北'];
        gc2['台湾']['阿莲'] = ['阿莲'];
        gc2['台湾']['安定'] = ['安定'];
        gc2['台湾']['安平'] = ['安平市'];
        gc2['台湾']['八德'] = ['八德'];
        gc2['台湾']['八里'] = ['八里'];
        gc2['台湾']['白河'] = ['白河市'];
        gc2['台湾']['白沙市'] = ['白沙市'];
        gc2['台湾']['板桥'] = ['板桥'];
        gc2['台湾']['褒忠'] = ['褒忠'];
        gc2['台湾']['宝山市'] = ['宝山市'];
        gc2['台湾']['卑南'] = ['卑南'];
        gc2['台湾']['北斗'] = ['北斗'];
        gc2['台湾']['北港'] = ['北港'];
        gc2['台湾']['北门'] = ['北门'];
        gc2['台湾']['北埔'] = ['北埔'];
        gc2['台湾']['北投'] = ['北投'];
        gc2['台湾']['补子'] = ['补子'];
        gc2['台湾']['布袋'] = ['布袋'];
        gc2['台湾']['草屯'] = ['草屯'];
        gc2['台湾']['长宾'] = ['长宾'];
        gc2['台湾']['长治市'] = ['长治区'];
        gc2['台湾']['潮州市'] = ['潮州县'];
        gc2['台湾']['车城'] = ['车城'];
        gc2['台湾']['成功'] = ['成功'];
        gc2['台湾']['城中区'] = ['城中区'];
        gc2['台湾']['池上'] = ['池上'];
        gc2['台湾']['春日'] = ['春日'];
        gc2['台湾']['刺桐'] = ['刺桐'];
        gc2['台湾']['高雄'] = ['高雄'];
        gc2['台湾']['花莲'] = ['花莲'];
        gc2['台湾']['基隆'] = ['基隆'];
        gc2['台湾']['嘉义'] = ['嘉义'];
        gc2['台湾']['苗栗'] = ['苗栗'];
        gc2['台湾']['南投'] = ['南投'];
        gc2['台湾']['屏东'] = ['屏东'];
        gc2['台湾']['台东'] = ['台东'];
        gc2['台湾']['台南'] = ['台南'];
        gc2['台湾']['台中'] = ['台中'];
        gc2['台湾']['桃园'] = ['桃园'];
        gc2['台湾']['新竹'] = ['新竹'];
        gc2['台湾']['宜兰'] = ['宜兰'];
        gc2['台湾']['彰化'] = ['彰化'];
        gc2['天津'] = [];
        gc2['天津']['宝坻'] = ['宝坻'];
        gc2['天津']['北辰'] = ['北辰'];
        gc2['天津']['大港'] = ['大港'];
        gc2['天津']['东丽'] = ['东丽'];
        gc2['天津']['汉沽'] = ['汉沽'];
        gc2['天津']['和平'] = ['和平'];
        gc2['天津']['河北'] = ['河北'];
        gc2['天津']['河东'] = ['河东'];
        gc2['天津']['河西'] = ['河西'];
        gc2['天津']['红桥'] = ['红桥'];
        gc2['天津']['蓟县'] = ['蓟县'];
        gc2['天津']['津南'] = ['津南'];
        gc2['天津']['静海'] = ['静海'];
        gc2['天津']['南开'] = ['南开'];
        gc2['天津']['宁河'] = ['宁河'];
        gc2['天津']['塘沽'] = ['塘沽'];
        gc2['天津']['武清'] = ['武清'];
        gc2['天津']['西青'] = ['西青'];
        gc2['西藏'] = [];
        gc2['西藏']['拉萨'] = ['达孜', '当雄', '堆龙德庆', '拉萨市', '林周', '墨竹工卡', '尼木', '曲水'];
        gc2['西藏']['阿里'] = ['措勤', '噶尔', '改则', '革吉', '普兰', '日土', '札达'];
        gc2['西藏']['昌都'] = ['八宿', '边坝', '察雅', '昌都', '丁青', '贡觉', '江达', '类乌齐', '洛隆', '芒康', '左贡'];
        gc2['西藏']['林芝'] = ['波密', '察隅', '工布江达', '朗县', '林芝', '米林', '墨脱'];
        gc2['西藏']['那曲'] = ['安多', '巴青', '班戈', '比如', '嘉黎', '那曲', '尼玛', '聂荣', '申扎', '索县'];
        gc2['西藏']['日喀则'] = ['昂仁', '白朗', '定结', '定日', '岗巴', '吉隆', '江孜', '康马', '拉孜', '南木林', '聂拉木', '仁布', '日喀则市', '萨嘎', '萨迦', '谢通门', '亚东', '仲巴'];
        gc2['西藏']['山南'] = ['措美', '错那', '贡嘎', '加查', '浪卡子', '隆子', '洛扎', '乃东', '琼结', '曲松', '桑日', '扎囊'];
        gc2['香港'] = [];
        gc2['香港']['北区'] = ['北区'];
        gc2['香港']['大埔区'] = ['大埔区'];
        gc2['香港']['东区'] = ['东区'];
        gc2['香港']['观塘区'] = ['观塘区'];
        gc2['香港']['黄大仙区'] = ['黄大仙区'];
        gc2['香港']['九龙'] = ['九龙区'];
        gc2['香港']['葵青区'] = ['葵青区'];
        gc2['香港']['离岛区'] = ['离岛区'];
        gc2['香港']['南区'] = ['南区'];
        gc2['香港']['荃湾区'] = ['荃湾区'];
        gc2['香港']['沙田区'] = ['沙田区'];
        gc2['香港']['深水?肚?'] = ['深水?肚?'];
        gc2['香港']['屯门区'] = ['屯门区'];
        gc2['香港']['湾仔区'] = ['湾仔区'];
        gc2['香港']['西贡区'] = ['西贡区'];
        gc2['香港']['香港'] = ['香港'];
        gc2['香港']['新界'] = ['新界'];
        gc2['香港']['油尖旺区'] = ['油尖旺区'];
        gc2['香港']['元朗区'] = ['元朗区'];
        gc2['香港']['中西区'] = ['中西区'];
        gc2['新疆'] = [];
        gc2['新疆']['乌鲁木齐'] = ['乌鲁木齐市', '乌鲁木齐县'];
        gc2['新疆']['阿克苏'] = ['阿克苏市', '阿瓦提', '拜城', '柯坪', '库车', '沙雅', '温宿', '乌什', '新和'];
        gc2['新疆']['阿拉尔'] = ['阿拉尔'];
        gc2['新疆']['阿勒泰'] = ['阿勒泰', '布尔津', '福海', '富蕴', '哈巴河', '吉木乃', '青河'];
        gc2['新疆']['巴音郭楞'] = ['博湖', '和静', '和硕', '库尔勒', '轮台', '且末', '若羌', '尉犁', '焉耆'];
        gc2['新疆']['博尔塔拉'] = ['博乐', '精河', '温泉'];
        gc2['新疆']['昌吉'] = ['昌吉市', '阜康', '呼图壁', '吉木萨尔', '玛纳斯', '米泉', '木垒', '奇台'];
        gc2['新疆']['哈密'] = ['巴里坤', '哈密市', '伊吾'];
        gc2['新疆']['和田'] = ['策勒', '和田市', '和田县', '洛浦', '民丰', '墨玉', '皮山', '于田'];
        gc2['新疆']['喀什'] = ['巴楚', '伽师', '喀什市', '麦盖提', '莎车', '疏附', '疏勒', '塔什库尔干塔吉克', '叶城', '英吉沙', '岳普湖', '泽普'];
        gc2['新疆']['克拉玛依'] = ['克拉玛依'];
        gc2['新疆']['克孜勒苏柯尔克孜'] = ['阿合奇', '阿克陶', '阿图什', '乌恰'];
        gc2['新疆']['石河子'] = ['石河子'];
        gc2['新疆']['塔城'] = ['额敏', '和布克赛尔', '沙湾', '塔城市', '托里', '乌苏', '裕民'];
        gc2['新疆']['图木舒克'] = ['图木舒克'];
        gc2['新疆']['吐鲁番'] = ['鄯善', '吐鲁番市', '托克逊'];
        gc2['新疆']['五家渠'] = ['五家渠'];
        gc2['新疆']['伊犁'] = ['察布查尔锡伯', '巩留', '霍城', '奎屯', '尼勒克', '特克斯', '新源', '伊宁市', '伊宁县', '昭苏'];
        gc2['云南'] = [];
        gc2['云南']['昆明'] = ['安宁', '呈贡', '富民', '晋宁', '昆明市', '禄劝', '石林', '嵩明', '寻甸', '宜良'];
        gc2['云南']['保山'] = ['保山市', '昌宁', '龙陵', '施甸', '腾冲'];
        gc2['云南']['楚雄'] = ['楚雄市', '大姚', '禄丰', '牟定', '南华', '双柏', '武定', '姚安', '永仁', '元谋'];
        gc2['云南']['大理'] = ['宾川', '大理市', '洱源', '鹤庆', '剑川', '弥渡', '南涧', '巍山', '祥云', '漾濞', '永平', '云龙'];
        gc2['云南']['德宏'] = ['梁河', '陇川', '潞西', '瑞丽', '盈江'];
        gc2['云南']['迪庆'] = ['德钦', '维西', '香格里拉'];
        gc2['云南']['红河'] = ['个旧', '河口', '红河', '建水', '金平', '开远', '泸西', '绿春', '蒙自', '弥勒', '屏边', '石屏', '元阳'];
        gc2['云南']['丽江'] = ['华坪', '丽江市', '宁蒗', '永胜', '玉龙'];
        gc2['云南']['临沧'] = ['沧源', '凤庆', '耿马', '临沧市', '双江', '永德', '云县', '镇康'];
        gc2['云南']['怒江'] = ['福贡', '贡山', '兰坪', '泸水'];
        gc2['云南']['曲靖'] = ['富源', '会泽', '陆良', '罗平', '马龙', '曲靖市', '师宗', '宣威', '沾益'];
        gc2['云南']['思茅'] = ['江城', '景东', '景谷', '澜沧', '孟连', '墨江', '普洱', '思茅市', '西盟', '镇沅'];
        gc2['云南']['文山'] = ['富宁', '广南', '麻栗坡', '马关', '丘北', '文山', '西畴', '砚山'];
        gc2['云南']['西双版纳'] = ['景洪', '勐海', '勐腊'];
        gc2['云南']['玉溪'] = ['澄江', '峨山', '华宁', '江川', '通海', '新平', '易门', '玉溪市', '元江'];
        gc2['云南']['昭通'] = ['大关', '鲁甸', '巧家', '水富', '绥江', '威信', '盐津', '彝良', '永善', '昭通市', '镇雄'];
        gc2['浙江'] = [];
        gc2['浙江']['杭州'] = ['淳安', '富阳', '杭州市', '建德', '临安', '桐庐'];
        gc2['浙江']['湖州'] = ['安吉', '长兴', '德清', '湖州市'];
        gc2['浙江']['嘉兴'] = ['海宁', '海盐', '嘉善', '嘉兴市', '平湖', '桐乡'];
        gc2['浙江']['金华'] = ['东阳', '金华市', '兰溪', '磐安', '浦江', '武义', '义乌', '永康'];
        gc2['浙江']['丽水'] = ['缙云', '景宁', '丽水市', '龙泉', '青田', '庆元', '松阳', '遂昌', '云和'];
        gc2['浙江']['宁波'] = ['慈溪', '奉化', '宁波市', '宁海', '象山', '余姚'];
        gc2['浙江']['衢州'] = ['常山', '江山', '开化', '龙游', '衢州市'];
        gc2['浙江']['绍兴'] = ['上虞', '绍兴市', '绍兴县', '嵊州', '新昌', '诸暨'];
        gc2['浙江']['台州'] = ['临海', '三门', '台州市', '天台', '温岭', '仙居', '玉环'];
        gc2['浙江']['温州'] = ['苍南', '洞头', '乐清', '平阳', '瑞安', '泰顺', '温州市', '文成', '永嘉'];
        gc2['浙江']['舟山'] = ['岱山', '嵊泗', '舟山市'];
        gc2['重庆'] = [];
        gc2['重庆']['巴南'] = ['巴南'];
        gc2['重庆']['北碚'] = ['北碚'];
        gc2['重庆']['璧山'] = ['璧山'];
        gc2['重庆']['长寿'] = ['长寿'];
        gc2['重庆']['城口'] = ['城口'];
        gc2['重庆']['大渡口'] = ['大渡口'];
        gc2['重庆']['大足'] = ['大足'];
        gc2['重庆']['垫江'] = ['垫江'];
        gc2['重庆']['丰都'] = ['丰都'];
        gc2['重庆']['奉节'] = ['奉节'];
        gc2['重庆']['涪陵'] = ['涪陵'];
        gc2['重庆']['合川'] = ['合川'];
        gc2['重庆']['江北'] = ['江北'];
        gc2['重庆']['江津'] = ['江津'];
        gc2['重庆']['九龙坡'] = ['九龙坡'];
        gc2['重庆']['开县'] = ['开县'];
        gc2['重庆']['梁平'] = ['梁平'];
        gc2['重庆']['南岸'] = ['南岸'];
        gc2['重庆']['南川'] = ['南川'];
        gc2['重庆']['彭水'] = ['彭水'];
        gc2['重庆']['綦江'] = ['綦江'];
        gc2['重庆']['黔江'] = ['黔江'];
        gc2['重庆']['荣昌'] = ['荣昌'];
        gc2['重庆']['沙坪坝'] = ['沙坪坝'];
        gc2['重庆']['石柱'] = ['石柱'];
        gc2['重庆']['双桥'] = ['双桥'];
        gc2['重庆']['铜梁'] = ['铜梁'];
        gc2['重庆']['潼南'] = ['潼南'];
        gc2['重庆']['万盛'] = ['万盛'];
        gc2['重庆']['万州'] = ['万州'];
        gc2['重庆']['巫山'] = ['巫山'];
        gc2['重庆']['巫溪'] = ['巫溪'];
        gc2['重庆']['武隆'] = ['武隆'];
        gc2['重庆']['秀山'] = ['秀山'];
        gc2['重庆']['永川'] = ['永川'];
        gc2['重庆']['酉阳'] = ['酉阳'];
        gc2['重庆']['渝北'] = ['渝北'];
        gc2['重庆']['渝中'] = ['渝中'];
        gc2['重庆']['云阳'] = ['云阳'];
        gc2['重庆']['忠县'] = ['忠县'];
        gc2['其他'] = [];
        gc2['其他']['阿根廷'] = ['阿根廷'];
        gc2['其他']['埃及'] = ['埃及'];
        gc2['其他']['爱尔兰'] = ['爱尔兰'];
        gc2['其他']['奥地利'] = ['奥地利'];
        gc2['其他']['奥克兰'] = ['奥克兰'];
        gc2['其他']['澳大利亚'] = ['澳大利亚'];
        gc2['其他']['巴基斯坦'] = ['巴基斯坦'];
        gc2['其他']['巴西'] = ['巴西'];
        gc2['其他']['保加利亚'] = ['保加利亚'];
        gc2['其他']['比利时'] = ['比利时'];
        gc2['其他']['冰岛'] = ['冰岛'];
        gc2['其他']['朝鲜'] = ['朝鲜'];
        gc2['其他']['丹麦'] = ['丹麦'];
        gc2['其他']['德国'] = ['德国'];
        gc2['其他']['俄罗斯'] = ['俄罗斯'];
        gc2['其他']['法国'] = ['法国'];
        gc2['其他']['菲律宾'] = ['菲律宾'];
        gc2['其他']['芬兰'] = ['芬兰'];
        gc2['其他']['哥伦比亚'] = ['哥伦比亚'];
        gc2['其他']['韩国'] = ['韩国'];
        gc2['其他']['荷兰'] = ['荷兰'];
        gc2['其他']['加拿大'] = ['加拿大'];
        gc2['其他']['柬埔寨'] = ['柬埔寨'];
        gc2['其他']['喀麦隆'] = ['喀麦隆'];
        gc2['其他']['老挝'] = ['老挝'];
        gc2['其他']['卢森堡'] = ['卢森堡'];
        gc2['其他']['罗马尼亚'] = ['罗马尼亚'];
        gc2['其他']['马达加斯加'] = ['马达加斯加'];
        gc2['其他']['马来西亚'] = ['马来西亚'];
        gc2['其他']['毛里求斯'] = ['毛里求斯'];
        gc2['其他']['美国'] = ['美国'];
        gc2['其他']['秘鲁'] = ['秘鲁'];
        gc2['其他']['缅甸'] = ['缅甸'];
        gc2['其他']['墨西哥'] = ['墨西哥'];
        gc2['其他']['南非'] = ['南非'];
        gc2['其他']['尼泊尔'] = ['尼泊尔'];
        gc2['其他']['挪威'] = ['挪威'];
        gc2['其他']['葡萄牙'] = ['葡萄牙'];
        gc2['其他']['其它地区'] = ['其它地区'];
        gc2['其他']['日本'] = ['日本'];
        gc2['其他']['瑞典'] = ['瑞典'];
        gc2['其他']['瑞士'] = ['瑞士'];
        gc2['其他']['斯里兰卡'] = ['斯里兰卡'];
        gc2['其他']['泰国'] = ['泰国'];
        gc2['其他']['土耳其'] = ['土耳其'];
        gc2['其他']['委内瑞拉'] = ['委内瑞拉'];
        gc2['其他']['文莱'] = ['文莱'];
        gc2['其他']['乌克兰'] = ['乌克兰'];
        gc2['其他']['西班牙'] = ['西班牙'];
        gc2['其他']['希腊'] = ['希腊'];
        gc2['其他']['新加坡'] = ['新加坡'];
        gc2['其他']['新西兰'] = ['新西兰'];
        gc2['其他']['匈牙利'] = ['匈牙利'];
        gc2['其他']['以色列'] = ['以色列'];
        gc2['其他']['意大利'] = ['意大利'];
        gc2['其他']['印度'] = ['印度'];
        gc2['其他']['印度尼西亚'] = ['印度尼西亚'];
        gc2['其他']['英国'] = ['英国'];
        gc2['其他']['越南'] = ['越南'];
        gc2['其他']['智利'] = ['智利'];
        return gc2[province][city]
    }
    componentDidMount() {
        const{province}=this.state
        const cities=province!==''? this.getCity(province):[]
        const counties=province!==''? this.getCounty(province, this.getCity(province)[0]):[]
        this.setState({
            cities,counties
        })
        
    }
    handleChange = (name) => {
        return async (e) => {
            e.preventDefault()
            const { handelChange, required,county } = this.props
            const { city, province} = this.state
            const value = e.target.value
            switch (name) {

                case "province":
                    this.setState({
                        province: value,
                        cities: value === '' ? [] : this.getCity(value),
                        // city: e.target.value === '' ? '' : this.getCity(e.target.value)[0],
                        counties: value === '' ? [] : this.getCounty(value, this.getCity(value)[0])
                    }, () => {
                        if (!required) {
                            handelChange({ target: { value: value === '' ? '' : value } })
                        }else{
                            handelChange({ target: { value: '' } })
                        }

                    });
                    console.log()
                    break;
                case "city":
                    this.setState({
                        city: value,
                        counties: value === '' ? [] : this.getCounty(province, value),
                        // county: value === '' ? '' : this.getCounty(province, value)[0]
                    }, () => {
                        if(!county&&value !== ''){
                            handelChange({ target:{value : {province,city:value} } })
                            console.log(JSON.stringify({province,city:value}) )
                        }else{
                            if (!required) {
                                handelChange({ target: { value: value === '' ? province : value } })
                            } else {
                                handelChange({ target: { value: '' } })
                            }
                        }
                      

                    });
                    break;
                case "county":
                    this.setState({
                        county: value === '' ? '' : value
                    }, () => {
                        if (!required) {
                            handelChange({ target: { value: value === '' ? city : value } })
                        } else {
                            handelChange({
                                target: {
                                    value: value === '' ? '' : {
                                        province,
                                        city,
                                        area: value
                                    }
                                }
                            })
                        }

                    });
                    break;
                default:
                    alert("child handleChange error")
            }

        }
    }

    render() {
        let id = 0
        const { city, province, county } = this.state
        const { disabled } = this.props
        return (
            <div className="DJsbAc" >

                <select disabled={disabled} className={form["select"]} value={province} onChange={this.handleChange("province")}>
                    <option value='' >{'省份'}</option>

                    {this.state.provinces.map(province => (
                        <option value={province} key={id++}>{province}</option>
                    ))}
                </select>
                <select disabled={disabled} className={form["select"]} value={city} onChange={this.handleChange("city")} style={{ margin: '0 10px ' }}>
                    <option value='' >{'城市'}</option>
                    {this.state.cities.map(city => (
                        <option value={city} key={id++}>{city}</option>
                    ))}
                </select>
                {county?  <select disabled={disabled} className={form["select"]} value={county} onChange={this.handleChange("county")}>
                    <option value='' >{'市区'}</option>
                    {this.state.counties.map(county => (
                        <option value={county} key={id++}>{county}</option>
                    ))}
                </select>:null}
              
            </div>
        )
    }
}