
/*函数节流*/
export const throttle = (fn, interval) => {
  var enterTime = 0;//触发的时间
  var gapTime = interval || 300;//间隔时间，如果interval不传，则默认300ms
  return function () {
    var context = this;
    var backTime = new Date();//第一次函数return即触发的时间
    if (backTime - enterTime > gapTime) {
      fn.call(context, arguments);
      enterTime = backTime;//赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  };
}
/*函数防抖*/
export function debounce(fn, interval) {
  var timer;
  var gapTime = interval || 200;//间隔时间，如果interval不传，则默认200ms
  return function () {
    clearTimeout(timer);
    var context = this;
    var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
    timer = setTimeout(function () {
      fn.call(context, args);
    }, gapTime);
  };
}


export const getTomorrow=()=> {
  const day = new Date();
  day.setTime(day.getTime() + 24 * 60 * 60 * 1000);
  return (
    day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate()
  );
}
export const getToday=()=> {
  const day = new Date();
  day.setTime(day.getTime());
  return (
    day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate()
  );
}
export const getNowFormatDate=(day)=> {
    var date =day;
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}
export const getQueryVariable=(variable)=>
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] === variable){return pair[1];}
       }
       return(false);
}