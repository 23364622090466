import React, { PureComponent } from 'react';
import Scroll from '@/components/Scroll'
import ReactTree from '@/components/ReactTree/components/container'
import _sidebar from './sidebar.module.scss'
import { dropdownShowContext } from '@/components/ReactTree/js/createContext.js';
import _sidebar_dropdown from '@/components/ReactTree/css/sidebar_dropdown.module.scss'
import { activelink } from '@/components/ReactTree/css/sidebar_item.module.scss';
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
const { Provider } = dropdownShowContext
class ReactTreeBar extends PureComponent {
    constructor(props) {
        super(props)
        this.timeout = null
        this.ScrollRef = React.createRef()
        this.state = {
            TreeSelectVal: [],
            treeKey: nanoid()
        }
    }
    ParentCallBack = null//顶层数据的光亮事件
    ParentKeyObj = {}//记录所有点击事件
    selectParentKey = null//选中的key
    ParentEventActive = false//父级是否光亮
    PreParentKey = []//之前的点击的父级key
    static propTypes = {
        Navigation: PropTypes.bool,//导航模式
        SreachText: PropTypes.object,//树查找的值
        TreeData: PropTypes.array,//渲染树的数据
        ReactTreeClickEvent: PropTypes.any,//元素的点击事件
        SidebarLinkFnTo: PropTypes.any,//是否高亮选中(元素的触发事件this.props, this.state, this.SidebarLinkRef暴露出去)
        active_condition: PropTypes.any,//是否移除active的判断条件(事件)
        show: PropTypes.bool,//显示或隐藏
        ScrollClick: PropTypes.bool, // 是否开启点击移动
        rounded: PropTypes.number,// 图片类型
        Single: PropTypes.object, // 是否开启单选,和多选的值设定
        AllSelectVal: PropTypes.array, // 默认的所有值
        init: PropTypes.bool, // 默认的所有值

    }
    static TreeSelectVal = []//树选中的值

    static defaultProps = {
        Navigation: false,
        init: false,
        SreachText: {
            tree_text: '',
            Sreachkey: ''
        },
        TreeData: [],
        Single: {
            open_val: true,
            Single_val_key: '',
            Single_header: false
        },
        AllSelectVal: [],
        ReactTreeClickEvent: null,
        active_condition: null,
        SidebarLinkFnTo: null,
        show: true,
        ScrollClick: false,
        sidebar_dropdown: [_sidebar_dropdown['sidebar-dropdown'], 'list-unstyled'].join(' '),
        collapse: ' collapse',
        collapsing: ' collapsing',
        show_dropdown: ' collapse show',
        rounded: 0

    }
    componentWillUnmount(){
        this.setState({
            TreeSelectVal: ReactTreeBar.TreeSelectVal
        },()=>{
            ReactTreeBar.TreeSelectVal =[] 

        })
    }
    getTreeSelectVal = (val, del = false, top = false) => {
        if (top) {
            // this.setState({ TreeSelectVal: del ? [] : val })
            ReactTreeBar.TreeSelectVal = del ? [] : val
            return
        }
        // const { Single: { Single_val_set=true } } = this.props
        const index = ReactTreeBar.TreeSelectVal.indexOf(val)
        const callback = () => {
            if (index !== -1) {
                ReactTreeBar.TreeSelectVal.splice(index, 1);
            } else {
                ReactTreeBar.TreeSelectVal.push(val)
            }
        }
        if (Object.prototype.toString.call(val) === '[object Array]') {//多选处理
            if (del) {
                // 从一个数组中删除另一个数组中存在的元素
                ReactTreeBar.TreeSelectVal = ReactTreeBar.TreeSelectVal.filter((x) => !val.some((item) => x === item));
            } else {
                let arr = [...ReactTreeBar.TreeSelectVal, ...val]
                ReactTreeBar.TreeSelectVal = Array.from(new Set(arr))
            }
        } else {
            callback()
        }
        this.setState({
            TreeSelectVal: ReactTreeBar.TreeSelectVal
        })
    }
    setKey = () => {
        this.setState({
            treeKey: nanoid()
        })
    }
    switchtarget = (e) => {//元素审查
        switch (e.target.tagName) {
            case 'A':
                return false;
            case 'SPAN':
                return e.target.parentElement.parentElement;
            case 'INPUT':
                return e.target.parentElement.parentElement;
            default:
                return e.target.parentElement;
        }
    }
    TryType = (obj, type) => {//类目动作判断
        const { collapse, sidebar_dropdown, show_dropdown, collapsing } = this.props//自行理解
        const { elUl, elLiLength } = obj//自行理解
        let elLiHeight = 0;//获取li标签的高
        let str = ''//拼接数组字符串
        const getClassList = (getval) => {//拼接ul 的类名
            str = sidebar_dropdown + getval //根据传过来的类名来觉得ul增加的类名
            elUl.classList = str.split(',')//把字符串切割成数组
        }
        switch (type) {
            case show_dropdown: //隐藏要显示的操作
                elUl.parentElement.children[0].setAttribute('aria-expanded', true)
                getClassList(collapsing)//第一次获取
                // 等待中转换成collapsing时获取高度
                elLiHeight = elLiLength ? elUl.children[0].offsetHeight : 0    //获取li标签的高度
                elUl.style.height = elLiHeight * elLiLength + 'px'    // 这里是为了获取li标签总数的高度
                this.timeout = setTimeout(() => {
                    elUl.style.height = ''
                    // 等待完毕转换成show_dropdown
                    getClassList(type)//这里是类名切换成show_dropdown
                    this.timeout = null
                }, 350)

                break;
            case collapse:
                elUl.style.height = elUl.parentElement.children[1].offsetHeight + 'px'
                elUl.parentElement.children[0].setAttribute('aria-expanded', false)
                getClassList(collapsing)
                setTimeout(() => {
                    //获取li标签的高度
                    elUl.style.height = 0   // 这里是为了获取li标签总数的高度
                }, 0)

                this.timeout = setTimeout(() => {
                    elUl.style.height = ''
                    getClassList(type)//这里是类名切换成collapse
                    this.timeout = null
                }, 350)

                break;
            default: return;
        }
    }
    dropdownShow = (e, visible) => { //点击隐藏或者显示元素的触发方法
        if (!visible) {
            e.preventDefault()
            e.stopPropagation()
        }
        if (this.timeout && !visible) {//r如果上一个切换的动作没结束那就禁止
            return
        }
        // 1.switch  判断e.target.tagName  retrun false
        const el = !visible ? this.switchtarget(e) : e.parentElement
        if (!el || el.children.length < 2) {
            return
        }
        const elUl = el.children[1];//获取UL元素
        const elLiLength = elUl.children.length//获取li标签总数量
        const { collapse, show_dropdown } = this.props//自行理解
        const showDrop = (/^true$/i).test(elUl.parentElement.children[0].getAttribute('aria-expanded')); //自行理解
        const obj = {
            elUl, elLiLength
        }
        if (visible) {
            this.TryType(obj, show_dropdown)

        } else {
            if (!showDrop) {
                this.TryType(obj, show_dropdown)
            } else {
                this.TryType(obj, collapse)
            }
        }


        setTimeout(() => {
            if (this.ScrollRef.current && this.ScrollRef.current.ScrollRefresh) {
                this.ScrollRef.current.ScrollRefresh()

            }
        }, 300)
    }
    ReactTreeClickEvent = (el, List, _this, callBack) => {//多层级的点击事件
        // // el 点击的整行元素(父)
        // // List 渲染的组件数组
        // // _this 点击的当前组件实例,可以拿pros和state等等....
        // // callBack 点中的光亮事件
        // // class_Str 单行组件选中的类名
        if (this.props.Single.open_val) {
            const { ParentKey } = _this.props
            const active = _this.state.active
            if (!this.ParentKeyObj[ParentKey]) {//把点击过的都收集起来
                this.ParentKeyObj[ParentKey] = {
                    callBack,
                    active,
                    el
                }
            }
            const ParentKey_index = this.PreParentKey.indexOf(ParentKey)
            if (el.innerHTML.indexOf(activelink) !== -1) {
                // 如果含有被选中元素储存起来
                if (ParentKey_index === -1) {
                    // // 如果含有被选中元素储存起来
                    this.PreParentKey.push(ParentKey)
                }
            } else {
                callBack() // 如果是数据里面有被选中的数据(下拉除外),那么记录下他的选中光亮才被关闭
            }
        }

        if (this.props.ReactTreeClickEvent) {
            this.props.ReactTreeClickEvent({ el, List, _this })

        }
    }
    SidebarLinkFnTo = (_props, _state, _ref, callBack) => {//单层级的点击事件
   
        // _props 点击组件的props
        // _state  点击组件的state
        // _ref  点击组件的第一个元素ref
        // callBack 点中的光亮事件
        if (this.props.Single.open_val) {
            if (_state.active) { return }//如果光亮就不执行
            const { ParentKey } = _props
            const { active } = _state
            this.selectParentKey = ParentKey
            if (!this.ParentKeyObj[ParentKey]) {//把点击过的都收集起来,因为是单层所以没有show
                this.ParentKeyObj[ParentKey] = {
                    callBack,
                    active,
                    el: _ref.current
                }
            }
            callBack()
        }
        const pathname = window.location.pathname
        if (this.props.SidebarLinkFnTo &&  _props.this_item.path!==pathname) {
            this.props.SidebarLinkFnTo({ _props, _state, _ref })
        }

    }
    active_condition = (_props, _state, callBack) => {//单层选中后的事件监听
        // callBack 选中的单层组件的光亮状态改变事件
        // item 单层组件的里的数据
        if (this.props.Single.open_val) {
            const { ParentKey } = _props
            if (this.selectParentKey !== ParentKey) {//如果和当前的页面的地址不符合,取消自身的选中光亮
                if (this.PreParentKey.length) {
                    this.PreParentKey.forEach(element => {
                        const ParentKey_index = this.PreParentKey.indexOf(element)
                        const { el, callBack: _callBack } = this.ParentKeyObj[element]
                        const showDrop = (/^true$/i).test(el.children[0].getAttribute('aria-expanded')); //自行理解
                        if (el.innerHTML.indexOf(activelink) !== -1 && !showDrop) {
                            // 如果是数据里面有被选中的数据(下拉除外),那么记录下他的选中光亮要被关闭
                            _callBack()
                            setTimeout(() => {
                                this.PreParentKey.splice(ParentKey_index, 1)

                            })
                        }
                    });
                }
                callBack()
            }


        }
        if (this.props.active_condition) {
            this.props.active_condition({ _props, _state, callBack })
        }

    }
    render() {
        const {
            show,
            TreeData,
            ScrollClick,
            rounded,
            Single,
            SreachText,
            AllSelectVal,
            init,
            Navigation,
            treeKey
        } = this.props
        const {
            ReactTreeClickEvent,
            active_condition,
            SidebarLinkFnTo
        } = this

        return (
            <nav id="sidebar" className={!show ? [_sidebar["sidebar"], _sidebar["collapsed"]].join(' ') : _sidebar["sidebar"]}>
                <div className={[_sidebar["sidebar-content"]].join(' ')}>
                    {this.props.render?this.props.render().head||'':""}
                    <Scroll ref={this.ScrollRef}
                        render={(PushScroll, scrollXY, ScrollRefresh) => {
                            let _context = {
                                dropdownShow: this.dropdownShow,
                                ReactTreeClickEvent: ReactTreeClickEvent,
                                switchtarget: this.switchtarget,
                                SidebarLinkFnTo: SidebarLinkFnTo,
                                active_condition: active_condition,
                                getTreeSelectVal: this.getTreeSelectVal,
                                TreeSelectVal: ReactTreeBar.TreeSelectVal,
                                PushScroll: PushScroll,//点击移动到元素的事件
                                scrollXY: scrollXY,
                                rounded: rounded,
                                ScrollClick: ScrollClick,
                                TreeData: TreeData,
                                Single: Single,
                                SreachText: SreachText,
                                AllSelectVal: AllSelectVal,
                                Navigation:Navigation,
                                setKey: this.setKey,
                                init: init
                            };
                            return <Provider value={_context}>
                                <ReactTree key={treeKey} _context={_context}></ReactTree>
                            </Provider>

                        }}
                    />
                    {this.props.render?this.props.render().bottom||'':""}
                </div>
            </nav>

        );
    }
}

export default ReactTreeBar;