import { lazy } from 'react';
const Hotel = lazy(() => import('@/pages/Hotel'))
const changeData = lazy(() => import('@/pages/Hotel/pages/changeData'))
const HotelProduct = lazy(() => import('@/pages/HotelProduct'))
const HotelchangeData = lazy(() => import('@/pages/HotelProduct/pages/changeData'))
const HotelImageText = lazy(() => import('@/pages/HotelProduct/pages/ImageText'))
const HotelEnterPrice = lazy(() => import('@/pages/HotelProduct/pages/EnterPrice'))
const HotelEnterPriceBatch = lazy(() => import('@/pages/HotelProduct/pages/EnterPriceBatch'))
const RouteProduct = lazy(() => import('@/pages/RouteProduct'))
const RoutechangeData = lazy(() => import('@/pages/RouteProduct/pages/changeData'))
const RouteImageText = lazy(() => import('@/pages/RouteProduct/pages/ImageText'))
const RouteEnterPrice = lazy(() => import('@/pages/RouteProduct/pages/EnterPrice'))
const RouteEnterPriceBatch = lazy(() => import('@/pages/RouteProduct/pages/EnterPriceBatch'))
const Franchisee = lazy(() => import('@/pages/Franchisee'))
const FranchiseeChangeData = lazy(() => import('@/pages/Franchisee/pages/changeData'))
const cooperation = lazy(() => import('@/pages/cooperation'))
const Admin = lazy(() => import('@/pages/Admin'))
const Role = lazy(() => import('@/pages/Role'))
const Room = lazy(() => import('@/pages/Room'))
const AboutAndCustomer = lazy(() => import('@/pages/AboutAndCustomer'))
const Advertisement = lazy(() => import('@/pages/Advertisement'))
const CustomTour = lazy(() => import('@/pages/CustomTour'))
const Settlement = lazy(() => import('@/pages/Settlement'))
const Invoice = lazy(() => import( '@/pages/Settlement/pages/Invoice'))
const ProductOrder = lazy(() => import('@/pages/ProductOrder'))
const Statistics = lazy(() => import('@/pages/Statistics'))
const StatisticsTime = lazy(() => import('@/pages/StatisticsTime'))

export const routeTreeArr = [
    {
        meta: {
            title: '广告图',
        },
        children:[
            {

                path: '/Advertisement?type=1',
                meta: {
                    title: '酒店广告图',
                },
            },
            {

                path: '/Advertisement?type=2',
                meta: {
                    title: '路线广告图',
                },
            },
        ]
    },
    {
        meta: {
            title: '信息系统管理',
        },
        children: [
            {

                path: '/Admin',
                meta: {
                    title: '管理员信息',
                },
            },
        
            {
                path: '/Role',
                meta: {
                    title: '角色管理',
                },
            },
            {
                path: '/About',
                meta: {
                    title: '关于我们',
                },
            },

            {
                path: '/Customer',
                meta: {
                    title: '客户协议',
                },
            },
            {
                path: '/cooperation',
                meta: {
                    title: '求合作',
                },
            },
        ]
    },
    {
        meta: {
            title: '酒店管理',
        },
        children: [
            {
                path: '/HotelProduct?type=RobBuy',
                meta: {
                    title: '酒店抢购',
                },
            },
            {
                path: '/HotelProduct?type=Reserve',
                meta: {
                    title: '酒店预订',
                },
            },
            {
                path: '/Hotel',
                meta: {
                    title: '酒店管理',
                },
            },
        ]
    },
    {

        meta: {
            title: '路线管理',
        },
        children: [
            {
                path: '/RouteProduct?type=Parenting',
                meta: {
                    title: '亲子游',
                },
            },
            {
                path: '/RouteProduct?type=Periphery',
                meta: {
                    title: '周边游',
                },
            },
            {
                path: '/RouteProduct?type=Private',
                meta: {
                    title: '私家游',
                },
            },
        ]
    },
    {

        meta: {
            title: '定制管理',
        },
        children: [
            {
                // active: true,
                path: '/CustomTour?type=1',
                meta: {
                    title: '定制游',
                   
                },
            },
            {
                path: '/CustomTour?type=2',
                meta: {
                    title: '会议团建',
                   
                },
            },
        ]
    },
    {

        meta: {
            title: '供应商管理',
        },
        children: [
            {
                // active: true,
                path: '/Franchisee',
                meta: {
                    title: '供应商列表',
                },
            },
        ]
    },
    {

        meta: {
            title: '订单管理',
        },
        children: [
            {
                // active: true,
                path: '/ProductOrder?type=0',
                meta: {
                    title: '酒店订单',
                },
            },
            {
                // active: true,
                path: '/ProductOrder?type=2',
                meta: {
                    title: '路线订单',
                },
            }
           
        ]
    },
    {

        meta: {
            title: '统计管理',
        },
        children: [
            {
                path: '/Statistics?type=0',
                meta: {
                    title: '酒店统计(下单)',
                },
            },
            {
                path: '/Statistics?type=2',
                meta: {
                    title: '路线统计(下单)',
                },
            },
            {
                path: '/StatisticsTime?type=0',
                meta: {
                    title: '酒店统计(离店)',
                },
            },
            {
                path: '/StatisticsTime?type=2',
                meta: {
                    title: '路线统计(离团)',
                },
            },
        ]
    },
    {

        meta: {
            title: '财务管理',
        },
        children: [
            {
                // active: true,
                path: '/Settlement',
                meta: {
                    title: '支出单',
                },
            },
        ]
    },
 


]
export const router = [
    {
        path: '/Hotel',
        component: Hotel
    },
    {
        path: '/changeData',
        component: changeData
    },
    {
        path: '/HotelProduct',
        component: HotelProduct
    },
    {
        path: '/HotelchangeData',
        component: HotelchangeData
    },
    {
        path: '/HotelImageText',
        component: HotelImageText
    },
    {
        path: '/HotelEnterPrice',
        component: HotelEnterPrice
    },
    {
        path: '/HotelEnterPriceBatch',
        component: HotelEnterPriceBatch
    },
    {
        path: '/RouteProduct',
        component: RouteProduct
    },
    {
        path: '/RoutechangeData',
        component: RoutechangeData
    },
    {
        path: '/RouteImageText',
        component: RouteImageText
    },
    {
        path: '/RouteEnterPrice',
        component: RouteEnterPrice
    },
    {
        path: '/RouteEnterPriceBatch',
        component: RouteEnterPriceBatch
    },

    {
        path: '/cooperation',
        component: cooperation
    },
    {
        path: '/Franchisee',
        component: Franchisee
    },
    {
        path: '/FranchiseeChangeData',
        component: FranchiseeChangeData
    },
    {
        path: '/Role',
        component: Role
    },
    {
        path: '/Admin',
        component: Admin
    },

    {
        path: '/Room',
        component: Room
    },
    {
        path: '/About',
        component: AboutAndCustomer
    },
    {
        path: '/Customer',
        component: AboutAndCustomer
    },
    {
        path: '/CustomTour',
        component: CustomTour
    },
    {
        path: '/Advertisement',
        component: Advertisement
    },
    {
        path: '/Settlement',
        component: Settlement
    },
    {
        path: '/ProductOrder',
        component: ProductOrder
    },
    {
        path: '/Statistics',
        component: Statistics
    },
    {
        path: '/StatisticsTime',
        component: StatisticsTime
    },
    {
        path: '/Invoice',
        component: Invoice
    },


]
export const redirect = '/Admin'