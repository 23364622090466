import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper' // 引入Cropper
import 'cropperjs/dist/cropper.css' // 引入Cropper对应的css
import Modal from '@/components/Modal';
import './ClassCropperModal.scss'

export default class ClassCropperModal extends Component {
  static propTypes = {
    uploadedImageFile: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      src: null,
      isShow: true
    }
    this.cropper = React.createRef()
  }

  componentDidMount() {
    const fileReader = new FileReader()
    fileReader.onload = e => {
      const dataURL = e.target.result
      this.setState({ src: dataURL })
    }

    fileReader.readAsDataURL(this.props.uploadedImageFile)
  }

  onCropperInit = (cropper) => { this.cropper = cropper }
  blobToFile = (blob, filename = "file", type) => {
    !type && console.error("请传入转化文件的类型type!!!");
    return new File([blob], filename, {
      type
    });
  }
  handleSubmit = () => {

    if (!this.state.submitting) {
      // let url = `/homepage_images` // 你要上传的url
      // 拿到文件名
      let filename = this.props.uploadedImageFile.name
      // TODO: 这里可以尝试修改上传图片的尺寸
      this.cropper.getCroppedCanvas().toBlob(async blob => {
        const File = []
        File.push(this.blobToFile(blob, filename, blob.type))
        this.props.onSubmit(File);
        this.modalClose()
        //   // 关闭弹窗
      })
    }
  }
  modalClose = () => {
    this.props.onClose()
    this.setState({isShow:false})
  }
  render() {
    const { isShow } = this.state
    return (
      <Modal
      zIndex={100000}
      size='xl'
        title="图片裁剪"
        isShow={isShow}
        bgc_type={'light'}
        modalClose={this.modalClose}
        submitClose={this.handleSubmit}
        render={() => {
          return  <div className="cropper-container-container">
          <div className="cropper-container">
            <Cropper
              src={this.state.src}
              className="cropper"
              onInitialized={this.onCropperInit.bind(this)}
              viewMode={1}
              zoomable={false}
              aspectRatio={'1:3'} // 固定为1:1  可以自己设置比例, 默认情况为自由比例
              guides={false}
              preview=".cropper-preview"
            />
          </div>
          <div className="preview-container">
            <div className="cropper-preview" />
          </div>
        </div>
        }}>
      </Modal>

    )
  }
}
