import React, { PureComponent, Fragment } from 'react'
import MultiLevelRouting from './MultiLevelRouting'
import SidebarItem from './SidebarItem'
import SingleHeader from './SingleHeader'
import { nanoid } from 'nanoid'
import _ReactTree from '@/components/ReactTree/css/ReactTree.module.scss'
import LazyImage from "@/components/easy-loadimage/easy-loadimage";
import { filterTree } from '@/js/for.js';
import { dropdownShowContext } from '@/components/ReactTree/js/createContext.js';
import { formatQueue } from '@/js/for.js';

import Condition from '@/components/config/rif'
const { Provider } = dropdownShowContext
let _this;
export default class ReactTree extends PureComponent {
    static contextType = dropdownShowContext
    constructor(props, context) {
        super(props, context)
        this.Arr = []
        this.text_arr = []
        this.PreSelectVal = this.context.AllSelectVal;
        this.selectVal = []
        this.PreSearchVal = [];
        this.searchVal = []
        const { TreeData } = context
        this.AllLevelLength = 0

        _this = this
        if (TreeData.length) {
            this.getStr(TreeData, this.Arr)

        }
    }
    state = {
        ParentActive: false,
        ParentClick: false,
        active: true,
        text_arr: [],
        pretext: ''
    }
    //若state的值在任何时候都取决于props，那么可以使用getDerivedStateFromProps
    static getDerivedStateFromProps(props, prestate) {
        let text_arr = []
        const { pretext } = prestate
        const { SreachText, TreeData } = props._context
        if (SreachText.tree_text === '') {
            return null
        } else {
            if (TreeData.length && (pretext !== SreachText.tree_text)) {
                let index = 0
                filterTree({
                    data: TreeData,
                    value: SreachText.tree_text, // 修改value，查看过滤效果
                    filterChildren: false,
                    filter: true,
                    filterXor: false,
                    prop: SreachText.Sreachkey,
                    callback: (node) => {
                        const nodeStr = node[SreachText.Sreachkey] || '';
                        const nodeStr2 = node.meta.title || '';
                        const nodeStrBl = nodeStr === SreachText.tree_text
                        const nodeStr2Bl = nodeStr2 === SreachText.tree_text
                        let value = ''
                        if (nodeStrBl && nodeStr2Bl) {
                            value = nodeStr
                        } else if (nodeStrBl) {
                            value = nodeStr
                        } else if (nodeStr2Bl) {
                            value = nodeStr2
                        }

                        if (value !== '') {
                            _this.recursiveFunction({...node,open:true}, index, text_arr )
                            index++
                        }
                    }
                })
                return { text_arr, pretext: SreachText.tree_text }
            } else return null
        }

    }

    componentDidMount() {
        this.setState({ Arr: this.Arr })
    }
    getStr = (list, arr) => {
        if (list.length) {
            list.map((item, index) => {
                return this.recursiveFunction(item, index, arr)
            })
        }
    }
    changeAllLevle = (num) => {
        const { SreachText } = this.context
        if (SreachText.tree_text !== '') {
            return
        }

        this.AllLevelLength += num
        if (this.AllLevelLength === this.context.TreeData.length) {//选中的下级数量和本级的总数量相等
            this.setState({
                ParentActive: true,
                ParentClick: true,
                active: false,
            })
        } else {
            this.setState({
                ParentActive: false,
                ParentClick: false,
                active: true,

            })

        }
    };
    recursiveFunction = (item, key, Arr) => {
        const {
            name = false,
            children = false,
            visible = false,
            // active = false
        } = item
        const title = item.title||item.meta.title
        const { rounded } = this.context
        var KeyId = nanoid()
        if (children) {
            Arr[key] =
                <MultiLevelRouting
                    key={KeyId}
                    visible={visible}
                    ParentKey={KeyId}
                    this_item={item}
                    child={children}
                    marginLeft={false}
                    name={name}
                    title={title}
                    render={(scrollY) => {
                        return {
                            Icon: Object.prototype.toString.call(item.img) === "[object String]" ? <LazyImage
                                imageSrc={item.img}
                                alt="Image"
                                rounded={rounded}
                                preventImgClick
                                scrollTop={scrollY}
                                sizeW='20px'
                                sizeH='20px'
                            /> : item.img
                        }
                    }}
                > </MultiLevelRouting>
        } else {
            Arr[key] =
                <SidebarItem
                    this_item={item}
                    ParentKey={KeyId}
                    key={KeyId}
                    name={name}
                    marginLeft={false}
                    title={title}
                    visible={visible}
                    render={(scrollY) => {
                        return {
                            Icon: Object.prototype.toString.call(item.img) === "[object String]" ? <LazyImage
                                imageSrc={item.img}
                                alt="Image"
                                rounded={rounded}
                                preventImgClick
                                scrollTop={scrollY}
                                sizeW='20px'
                                sizeH='20px'
                            /> : item.img
                        }
                    }}
                ></SidebarItem>
        }

    }
    AddDelBtnClick = (active) => {
        //   this.PreSearchVal = [];
        //     this.searchVal = []
        this.setState({ ParentActive: active, ParentClick: true, active: !active }, () => {
            if (active) {
                this.AllLevelLength = this.context.TreeData.length
            } else {
                this.AllLevelLength = 0
            }
            this.tellTree(!active)
        })
    }
    tellTree = (del = false) => {
        const { Single, getTreeSelectVal, TreeData } = this.context
        const this_item = { children: TreeData }

        if (this.PreSelectVal.length) { // 如果有遍历过直接拿
            this.selectVal = this.PreSelectVal
        } else {
            this.PreSelectVal = this.selectVal = formatQueue(this_item, Single.Single_val_key)//广式遍历
        }
        getTreeSelectVal(this.selectVal, del, true)//树节点处理点击的数据,del是true就是删除否则就是增加
    }
    render() {
        const { TreeData, Single, SreachText } = this.context
        const { ParentActive, ParentClick, Arr, text_arr } = this.state
        return (
            <Fragment>
                <Condition rif={Single.Single_header && SreachText.tree_text === ''}>
                    <SingleHeader AddDelBtnClick={this.AddDelBtnClick} ></SingleHeader>
                </Condition>
                <Condition rif={TreeData.length ? true : false}>
                    <Provider value={{
                        ...this.context,
                        ParentActive: ParentActive,
                        ParentClick: ParentClick,
                        changeAllLevle: this.changeAllLevle

                    }}>
                        <ul className={_ReactTree["sidebar-nav"]} style={{ display: SreachText.tree_text !== '' ? 'block' : 'none' }}>{text_arr}</ul>
                        <ul className={_ReactTree["sidebar-nav"]} style={{ display: SreachText.tree_text !== '' ? 'none' : 'block' }}>{Arr}</ul>
                    </Provider>
                </Condition>
                <Condition rif={(SreachText.tree_text !== '' ? text_arr.length : TreeData.length) ? false : true}>
                    <div className={_ReactTree['Not_yet']}>暂无数据...</div>
                </Condition>
            </Fragment>
        );
    }
}
