import React, { PureComponent, Fragment } from 'react'
import LazyImage from "@/components/easy-loadimage/easy-loadimage";
import img from '@/img/avatars/avatar.jpg'
import { AdminUpdatePassword } from '@/js/Interface/Admin'
import Modal from '@/components/Modal';
import Edit from './components/edit'
// import img1 from '@/img/avatars/avatar-5.jpg'
// import img2 from "@/img/avatars/avatar-2.jpg"
// import img3 from "@/img/avatars/avatar-3.jpg"
import NavLink from '@/components/Layout/components/NavLink';

import { Settings } from 'react-feather';
// import { Settings } from 'react-feather';
import Toggle from './components/toggle';
import Select from '@/components/Select';
import SearchInput from '@/components/Search_Input';
import Dropdowns from '@/components/Select/components/Dropdowns';
import './navbar.scss'
import './navbar_light.scss'
import './navbar_expand.scss'
import './nav.scss'
import './media.scss'

// import './avatar.scss'
class Breadcrumb extends PureComponent {
    editRefs = React.createRef()
    state = {
        isShow: false,
        userShow: false,
        noticeShow: false,
        messageShow: false
    }
    // SearchFn = (value) => {

    //     console.log(value)
    // }
    modalClose = () => {
        this.setState({
            isShow: false,
        })
    }
    submitClose = async () => {
        const verification = this.editRefs.current.Submit()
        if (verification) {
            const res = await AdminUpdatePassword({ ...verification })
            if (res.code === 0) {
                this.setState({
                    isShow: false,
                }, () => {
                    window.sessionStorage.clear();
                    window.location.reload();
                })
            }
        }
    }
    render() {
        // const GroupJson = [{
        //     imageSrc: '1',
        //     title: 'Update completed',
        //     describe: 'Restart server 12 to complete the update.',
        //     time: '2021/7/21 14:46:00'
        // },
        // {
        //     imageSrc: '2',
        //     title: 'Update completed',
        //     describe: 'Restart server 12 to complete the update.',
        //     time: '2021/7/21 14:46:00'
        // },
        // {
        //     imageSrc: '3',
        //     title: 'Update completed',
        //     describe: 'Restart server 12 to complete the update.',
        //     time: '2021/7/21 14:46:00'
        // },
        // {
        //     imageSrc: '4',
        //     title: 'Update completed',
        //     describe: 'Restart server 12 to complete the update.',
        //     time: '2021/7/21 14:46:00'
        // }]
        // const GroupJson2 = [
        //     {
        //         imageSrc: img,
        //         title: 'Update completed',
        //         describe: 'Restart server 12 to complete the update.',
        //         time: '2021/7/21 14:46:00'
        //     }, {
        //         imageSrc: img1,
        //         title: 'Update completed',
        //         describe: 'Restart server 12 to complete the update.',
        //         time: '2021/7/21 14:46:00'
        //     },
        //     {
        //         imageSrc: img2,
        //         title: 'Update completed',
        //         describe: 'Restart server 12 to complete the update.',
        //         time: '2021/7/21 14:46:00'
        //     },
        //     {
        //         imageSrc: img3,
        //         title: 'Update completed',
        //         describe: 'Restart server 12 to complete the update.',
        //         time: '2021/7/21 14:46:00'
        //     },
        // ]
        const GroupJson3 = [
            {
                title: '修改密码',
                istClickFn: (e, item) => {
                    this.setState({
                        isShow: true,
                    })
                }
            }
        ]
        // const { userShow, messageShow, noticeShow } = this.state
        const { showNavbar, tree_search, admin, show } = this.props
        const { isShow } = this.state
        return (
            <nav className="navbar navbar-expand navbar-light navbar-bg show">
                <Fragment>
                    <Toggle showNavbar={showNavbar}></Toggle>
                    <div className={!show ? ["sidebar", "collapsed"].join(' ') : "sidebar"}>
                        <SearchInput SearchFn={tree_search}></SearchInput>

                    </div>
                    <div className={show ? "navbar-collapse" : "navbar-collapse collapse"} >
                        <div className="navbar-nav navbar-align">
                            {/* <Select
                                num={GroupJson.length}
                                render={() => {
                                    return {
                                        _Icon: <Bell />,
                                        _Dropdowns: <Dropdowns GroupJson={GroupJson} />
                                    }

                                }} />
                            <Select
                                num={GroupJson.length}
                                render={() => {
                                    return {
                                        _Icon: <MessageSquare />,
                                        _Dropdowns: <Dropdowns GroupJson={GroupJson2} />
                                    }

                                }} /> */}
                            <Select
                                render={() => {
                                    return {
                                        _Icon:
                                            <div className="d-sm-none" >
                                                <Settings />
                                            </div>
                                        ,
                                        _link: <Fragment>
                                            <div className="DJsbAc">
                                                <div className="avatar img-fluid rounded mr-1" alt="Charles Hall" >
                                                    <LazyImage
                                                        imageSrc={img}
                                                        alt="Image"
                                                        preventImgClick
                                                    />
                                                </div>
                                                <div data-toggle="collapseLeft" className="sidebar-link collapsed" aria-expanded="false">
                                                    <span className="align-middle">{admin.account}</span>
                                                </div>

                                            </div>

                                        </Fragment>,
                                        _Dropdowns: <Dropdowns GroupJson={GroupJson3} diyHeader={'个人操作'} FooterTo={'login'}
                                            diyFooter={<NavLink to='login' className="text-muted" onClick={window.sessionStorage.clear()}>退出登录</NavLink>}
                                            align='center'
                                        />
                                    }

                                }} />

                        </div>

                    </div>
                    <Modal
                        size='md'
                        title={'修改密码'}
                        isShow={isShow}
                        bgc_type={'light'}
                        modalClose={this.modalClose}
                        submitClose={this.submitClose}
                        render={() => {
                            return <Edit ref={this.editRefs} />
                        }}>
                    </Modal>
                </Fragment>
            </nav>
        )
    }
}


export default Breadcrumb;