import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import form from './form.module.scss'
import { formContext } from './createContext.js';
import AddReceive from "@/components/AddReceive"
import Upload from "@/components/upload"
import { nanoid } from 'nanoid'
import Editor from "@/components/editor"
import DayPicker from '@/components/DayPicker';

export default class group extends Component {
    InputRef = React.createRef()
    UploadRefs = React.createRef()
    static propTypes = {
        type: PropTypes.string,
        label: PropTypes.string,
        addon: PropTypes.string,
        checkboxLabel: PropTypes.string,
        placeholder: PropTypes.string,
        name: PropTypes.string.isRequired,
        width: PropTypes.string,
        bottom: PropTypes.string,
        cols: PropTypes.string,
        rows: PropTypes.string,
        checkboxValue: PropTypes.string,
        disabled: PropTypes.bool,
        checkboxBtn: PropTypes.any,
        selectOnChange: PropTypes.func,
        buttonArr: PropTypes.array,
        obj: PropTypes.bool,
        none: PropTypes.bool,
        county: PropTypes.bool,


    }
    static defaultProps = {
        checkboxValue: '',
        checkboxLabel: 'title',
        addon: '',
        bottom: '',
        cols: "60",
        rows: "6",
        label: '',
        placeholder: '',
        type: 'text',
        width: "100%",
        none: false,
        disabled: false,
        checkboxBtn: false,
        county: true,
        obj: false,
        buttonArr: [],
        selectOnChange: () => { }

    }
    constructor(props, state) {
        super(props, state)
        const { TellForm } = this.context
        const initState = this.init()

        this.state = {
            ...initState
        }
        TellForm(this.checkCallBack, this.state.key)
    }

    static contextType = formContext;
    componentWillUnmount() {
        const { DelForm } = this.context
        DelForm(this.state.key)
    }
    componentDidMount() {
        const { name } = this.props

        const { defaultValue, verification } = this.context
        if ((defaultValue[name] || defaultValue[name] === 0) && verification[name]) {
            this.checkCallBack()
        }

    }
    init = () => {
        const { name, type } = this.props
        const { defaultValue, verification } = this.context
        const datetimeType = (type === "datetime-local")
        const file = (type === "file")
        const initState = {
            defaultValue: file ? [] : "",
            verification: {
                required: false
            },
            key: nanoid(),
            requiredShow: false,
            isNumber: false,
            all: false,
            text: null

        }
        if (defaultValue[name] || defaultValue[name] === 0) {
            let value = defaultValue[name]
            if (Object.prototype.toString.call(value) === "[object Number]") {
                initState.isNumber = true
                value = value + ''
            }
            if (file && Object.prototype.toString.call(value) === "[object Object]") {
                const copyvalue = []
                copyvalue.push(value)
                value = copyvalue

            }

            if (datetimeType) {
                if (value.indexOf("T") === -1) {
                    const val1 = value.slice(0, 10)
                    const val2 = value.slice(11, 16)
                    value = val1 + 'T' + val2
                }
            }
            if (verification[name]) {
                initState.defaultValue = value
                initState.verification = verification[name]
            } else {
                initState.defaultValue = value

            }

        } else {
            if (verification[name]) {
                initState.verification = verification[name]
            }
        }
        return initState
    }
    checkCallBack = () => {
        const { label, name, type } = this.props
        const { defaultValue, isNumber } = this.state
        const datetimeType = (type === "datetime-local")
        let changeValue = defaultValue
        if (datetimeType && defaultValue !== "") {
            changeValue = defaultValue.split('T')[0] + ' ' + defaultValue.split('T')[1] + ":00"
        }
        const setData = this.checkRequired(
            {
                target: {
                    value: defaultValue
                }
            }
        )

        this.setState({
            ...setData
        })
        return { requiredShow: setData.requiredShow, label, value: { [name]: isNumber ? Number(changeValue) : changeValue } }
    }
    checkRequired = (e) => {
        const { label, type } = this.props
        const { verification: { required, min = false, max = false } } = this.state
        const types = (type === "textarea" || type === "password" || type === "text" || type === "file" || type === "checkbox" || type === "editor")
        const text = (type === "textarea" || type === "password" || type === "text")
        const file = (type === "file" || type === "checkbox")
        const value = file ? (Array.isArray(e.target.value) ? e.target.value : e.target.value.split(',')) : e.target.value
        const checkValue = types ? (text ? (value + "").length : value[0] === "" ? false : value.length) : value
        const minText = label + (file ? "最少上传" + min + "张" : (types ? "最少填写" + min + '个文本' : "的值最小为" + min))
        const maxText = label + (file ? "最多上传" + max + "张" : (types ? "最多填写" + max + '个文本' : "的值最大为" + max))
        if (min && (checkValue < min)) {
            return { requiredShow: true, text: minText }
        } else if ((max || max === 0) && (checkValue > max)) {
            return { requiredShow: true, text: maxText }
        } else if (!checkValue && required) {
            return { requiredShow: true, text: label + "未被填写" }
        } else {
            return { requiredShow: false, text: '' }
        }
    }
    handelChange = (e) => {
        const { type, buttonArr, checkboxValue, selectOnChange } = this.props
        const { defaultValue, all } = this.state
        let value = e.target.value
        let changeValue = null
        let datetime = null
        let checkAll = all
        console.log(all)
        const datetimeType = (type === "datetime-local")
        if (type === "checkbox") {

            changeValue = defaultValue === "" ? [] : defaultValue
            const indexOf = changeValue.indexOf(value)
            if (value === 'all') {
                if (buttonArr.length !== defaultValue.length + 1) {
                    checkAll = true
                    buttonArr.map(item => {
                        if (item[checkboxValue] !== 'all') {
                            changeValue.push(item[checkboxValue] + '')
                        }
                    })
                } else {
                    checkAll = false
                    changeValue = []
                }

            } else {
                if (indexOf === -1) {
                    changeValue.push(value)
                } else {
                    changeValue.splice(indexOf, 1)
                }
      
                if (buttonArr.length === changeValue.length+1) {
                    checkAll = true
                } else {
                    checkAll = false
                }
            }

        } else if (type === "file") {
            changeValue = value.join(',')
        } else if (datetimeType) {
            if (value === "") {
                datetime = ""
                changeValue = ""
            } else {
                if (value.indexOf("T") !== -1) {
                    datetime = value
                    changeValue = value.split('T')[0] + ' ' + value.split('T')[1] + ":00"
                } else {
                    const val1 = value.slice(0, 10)
                    const val2 = value.slice(11, 16)
                    datetime = val1 + "T" + val2
                    changeValue = value
                }
            }


        } else {
            changeValue = value
        }

        this.setState({
            defaultValue: datetimeType ? datetime : changeValue,
            all:checkAll,
            ...this.checkRequired(e)
        }, () => {
            selectOnChange(changeValue)
        })

    }
    initSet = (val) => {
        this.setState({
            defaultValue: val,
            ...this.checkRequired({ target: { value: val } })
        })
    }
    isNumber = (val) => {
        var regPos = /^\d+(\.\d+)?$/; //非负浮点数
        var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
        if (regPos.test(val) || regNeg.test(val)) {
            return Number(val);
        } else {
            return val;
        }
    }
    hint_hide = (e) => {
        this.setState({ ...this.checkRequired(e) })
    };
    saveEditorContent = (values) => {
        const value = values === '<p></p>' ? "" : values
        this.handelChange({
            target: {
                value
            }
        })
    };

    submit = () => {

    };
    render() {
        const { label, addon, name, placeholder, type, width, bottom, disabled, buttonArr, children, checkboxValue, checkboxLabel, checkboxBtn, obj, cols, rows, none, clear, county } = this.props
        const { defaultValue, verification, requiredShow, text, all } = this.state
        const { min = 0, max = 99999 } = verification

        const { required = false } = verification
        const { labelWitdth } = this.context
        const check = (type !== "checkbox") || (type === "checkbox" && buttonArr.length)
        return (
            <Fragment>
                <div className={form["form-group"]}>
                    <div style={{ width: check ? labelWitdth : 'auto' }} className={[form["form-group-label"], required ? form["redStar"] : ""].join(" ")}>{label}：</div>
                    <div className={form["form-control"]}>
                        {check ?
                            <Fragment>
                                <div className={form["form-Fragment"]} style={{ maxWidth: width }}>
                                    {type === "editor" ?
                                        <Editor placeholder={placeholder} saveEditorContent={this.saveEditorContent} htmlContent={defaultValue} name={name} /> :
                                        type === "DayPicker" ?
                                            <DayPicker defaultValue={defaultValue} handelChange={this.handelChange} obj={obj} clear={clear} /> :
                                            type === "textarea" ?
                                                <textarea value={defaultValue} onChange={this.handelChange} name={name} cols={cols} rows={rows} maxLength={max} /> :
                                                type === "file" ?
                                                    <Upload defaultValue={defaultValue} verification={verification} handelChange={this.handelChange} name={name} ref={this.UploadRefs} /> :
                                                    type === "select" ?
                                                        <select name={name} value={defaultValue} onChange={this.handelChange} className={form["select"]} disabled={disabled}
                                                            style={{ ...(addon !== "") ? { borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {} }}
                                                        >
                                                            {none ? '' : <option key={nanoid()} value="">请选择{label}</option>}
                                                            {this.props.render ? this.props.render() : ""}
                                                        </select> :
                                                        type === "radio" ?
                                                            buttonArr.map(({ value, label, Outside }) => {
                                                                return (
                                                                    <Fragment key={nanoid()} >
                                                                        <label className="DJcAc" >
                                                                            <input
                                                                                checked={value + '' === defaultValue + ''}
                                                                                onChange={this.handelChange}
                                                                                disabled={disabled}
                                                                                value={value}
                                                                                name={name}
                                                                                type={type}
                                                                            />
                                                                            <span className={form["form-radio-label"]}>{label}</span>
                                                                        </label>
                                                                        {
                                                                            Outside ?
                                                                                Outside
                                                                                : ""
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                            :
                                                            type === "checkbox" ?
                                                                (!checkboxBtn ? buttonArr.map((item) => {
                                                                    return (
                                                                        <Fragment key={nanoid()} >
                                                                            <label >
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    checked={ item[checkboxValue] === "all" ? all :defaultValue.indexOf((checkboxValue === "" ? JSON.stringify(item) : item[checkboxValue] + "")) !== -1}
                                                                                    onChange={this.handelChange}
                                                                                    disabled={disabled}
                                                                                    value={checkboxValue === "" ? JSON.stringify(item) : item[checkboxValue] + ""}
                                                                                    name={name}
                                                                                    type={type}
                                                                                />
                                                                                <span className={form["form-radio-label"]}>{item[checkboxLabel]}</span>
                                                                            </label>
                                                                        </Fragment>
                                                                    )
                                                                }) :
                                                                    <div className="row">
                                                                        {
                                                                            buttonArr.map((item) => {
                                                                                return (
                                                                                    <div className="col col-4 Dc" style={{ marginBottom: '10px' }} key={nanoid()} >
                                                                                        <label className="DJfsAc">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                checked={defaultValue.indexOf((checkboxValue === "" ? JSON.stringify(item) : item[checkboxValue] + "")) !== -1}
                                                                                                onChange={this.handelChange}
                                                                                                disabled={disabled}
                                                                                                value={checkboxValue === "" ? JSON.stringify(item) : item[checkboxValue] + ""}
                                                                                                name={name}
                                                                                                type={type}
                                                                                            />
                                                                                            <span className={form["form-radio-label"] + ' ' + form['form-radio-label-checkBtn']} style={{ width: '130px' }}>{item[checkboxLabel]}</span>
                                                                                        </label>
                                                                                        <button style={{ marginRight: '10px' }} type="button" className="btn btn-primary" onClick={checkboxBtn.callBack(item)}>{checkboxBtn.title}</button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                                :
                                                                type === "receive" ?
                                                                    <AddReceive value={defaultValue} required={required} disabled={disabled} name={name} handelChange={this.handelChange} county={county} />
                                                                    :
                                                                    <input
                                                                        disabled={disabled}
                                                                        className={form["inputs"]}
                                                                        style={{ ...(addon !== "" && (type === 'text' || type === 'number')) ? { borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {} }}
                                                                        onChange={this.handelChange}
                                                                        min={min}
                                                                        max={max}
                                                                        value={defaultValue}
                                                                        name={name}
                                                                        onFocus={this.hint_hide}
                                                                        onBlur={this.hint_hide}
                                                                        type={type}
                                                                        dateformat='YYYY-MM-DD HH:mm:ss'
                                                                        placeholder={placeholder} />
                                    }
                                    {addon !== "" ? <div style={{ marginLeft: (type !== 'text' && type !== 'number' && type !== 'select') ? '20px' : "" }} className={form["input-group-addon"]}>{addon}</div> : ""}

                                </div>

                                {bottom !== "" ? <div className={form["bottom"]}> {bottom}</div> : ""}
                                {children ? <div style={{ margin: '1% 0' }}>
                                    {children}
                                </div> : ""}
                                {text !== "" && required && requiredShow ? <div className={[form["form-required"], required ? form["redStar"] : ""].join(" ")}> {text}</div> : ""}
                            </Fragment> : ''}
                    </div>

                </div>
                {!check ? <div >
                    {children}
                </div> : ""}
            </Fragment>

        )
    }
}
