import React, { PureComponent,Fragment} from 'react'
import './dropdown_menu.scss'
import './dropdown.scss'
import MenuHeader from './components/menu_header'
import MenuFooter from './components/menu_footer'
import ListGroupItem from './components/list_group_item'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
export default class index extends PureComponent{
    static propTypes = {
        GroupJson: PropTypes.array.isRequired,
        FooterDescribe: PropTypes.string,
        title: PropTypes.string,
        FooterTo: PropTypes.string,
        diyHeader: PropTypes.any,
        diyFooter: PropTypes.any,
        align: PropTypes.string,
        width: PropTypes.number,
    }
    static defaultProps = {
        GroupJson: [],
        FooterDescribe: '显示所有通知',
        FooterTo: '#',
        title:'通知',
        diyHeader: false,
        diyFooter: false,
      
        align:'left',
        width:320,

    }        

    render() {
        const { GroupJson,align} = this.props
        return (
     
                <Fragment>
                <MenuHeader num={GroupJson.length}  {...this.props}></MenuHeader>
                <div className="list-group">
                    {
                        GroupJson.map((item) => {
                            return <ListGroupItem key={nanoid()} JsonData={item} align={align}></ListGroupItem>
                        })
                    }
                </div>
                <MenuFooter  {...this.props}>
                </MenuFooter>
                </Fragment>
        )
    }
}
