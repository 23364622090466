import React, { PureComponent } from 'react'
import { findDOMNode } from 'react-dom'
import Condition from '@/components/config/rif'
import PropTypes from 'prop-types'
import './navbar_nav.scss'
import './nav_item.scss'

export default class index extends PureComponent {
    static propTypes = {
        width: PropTypes.number,
        num: PropTypes.number,
    }
    static defaultProps = {
        width: 320,
        num: 0
    }

    state = {
        isShow: false
    }
    dropdown = React.createRef()
    toggle = React.createRef()
    nav_item = React.createRef()
    componentDidMount() {
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e), false);
        const { current } = this.dropdown
        if (!current.getBoundingClientRect().left || current.getBoundingClientRect().right) {
            // console.log(this.dropdown.current.getBoundingClientRect().left,' dropdown-menu-left')

        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e), false);
    }
    TargetShow = () => {
        // const { current } = this.dropdown
        const { isShow } = this.state
        const { current: { classList } } = this.dropdown
        const { current: { classList: classList2 } } = this.toggle
        this.setState({ isShow: !isShow }, (a, b, c) => {
            const { isShow: NextIsShow } = this.state
            if (NextIsShow) {
                classList.add('show')
                classList2.add('show')
            } else {
                classList.remove('show')
                classList2.remove('show')
            }
        })
        // setTimeout(()=>{
        //     // console.log(current.getBoundingClientRect().left)
        //     if(current.getBoundingClientRect().right<current.offsetWidth){
        //         current.style.left=0
        //     }
        // },200)

    }

    handleClickOutside(e) {
        const target = e.target;
        const { isShow } = this.state
        if (this.nav_item.current && isShow) {
            // 组件已挂载且事件触发对象不在div内
            let result = findDOMNode(this.nav_item.current).contains(target);
            if (!result) {
                this.TargetShow()
            }
        }
    }

    render() {
        const { isShow } = this.state
        const { num} = this.props
        return (

            <div className="nav-item dropdown" ref={this.nav_item} >
                <div className="nav-icon dropdown-toggle" id="alertsDropdown" data-toggle="dropdown" ref={this.toggle} onClick={this.TargetShow}>
                    <div className="position-relative" >
                        <i className="align-middle" data-feather="bell">
                            {this.props.render()._Icon}
                        </i>
                        {
                            num ? <span className="indicator">{num}</span> : null
                        }

                    </div>
                </div>
                <div className="nav-link dropdown-toggle d-none d-sm-inline-block" data-toggle="dropdown" ref={this.toggle} onClick={this.TargetShow} >
                    {this.props.render()._link}
                </div>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="alertsDropdown" ref={this.dropdown} >
                    <Condition rif={isShow}>
                        {this.props.render()._Dropdowns}
                    </Condition>

                </div>

            </div>
        )
    }
}
