import request from './axios';
// 角色管理 - 修改
const RoleUpdate = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("put", '/Role/update', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}

//角色管理 - 全部
const RoleAll = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Role/all', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//角色管理 - 获取角色权限 所得到的后台菜单列表
const RoleGetPermissionMenu = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Role/getPermissionMenu', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//角色管理 - 列表
const RoleList = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Role/list', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//角色管理 - 删除/批量删除
const RoleDelete = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("delete", '/Role/delete', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 角色管理 - 新增
const RoleAdd = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("post", '/Role/add', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 角色管理 - 获取角色详情
const RoleGet = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", '/Role/get', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}

// 角色管理 - 获取角色
const PermissionAll = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", 'Permission/all', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 角色管理 - 角色授权
const RoleAuthorize = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("put", '/Role/authorize', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}




export {
    RoleAll,
    RoleUpdate,
    RoleAdd,
    RoleList,
    RoleDelete,
    RoleGet,
    RoleAuthorize,
    PermissionAll,
    RoleGetPermissionMenu
}
