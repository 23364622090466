import React, { PureComponent} from 'react'
import PropTypes from 'prop-types'
import NavLink from '@/components/Layout/components/NavLink';
import _brand from './sidebar_brand.module.scss'
export default class SidebarCta extends PureComponent{
    static propTypes = {
        title: PropTypes.any,
        toPage: PropTypes.string//去哪个页面
    }
    static defaultProps = {
        toPage: '/'
    }
    render() {
        const { title, toPage } = this.props
        return (
            <NavLink className={_brand["sidebar-brand"]} to={toPage}>
                <span className="align-middle">{title}
                </span>
            </NavLink>
        )
    }
}
